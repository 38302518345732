html, body {
  color: $primary;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  -webkit-font-smoothing: antialiased;
}


h1, h2, h3, h4, h5, h6 {
  color: $primary;
  font-family: $title-font-family;
  margin-top: 0;
  text-transform: none;

  &.card-title {
    font-family: $base-font-family;
  }

  &.card-title-sm {
    font-family: $base-font-family;
    font-size: 1em;
    margin-bottom: $vert-space/4;

    a {
      color: $action-default;

      &:hover {
        color: $action-hover;
      }
    }
  }
}

h1#page-title2 {
  margin-left: 30px;
  margin-right: 30px;
}

h1, h2 {
  font-weight: normal;
}

h3, h4, h5, h6 {
  font-weight: normal;
}

.l-region--sidebar-second {
  h3, h4, h5, h6 {
    font-family: $base-font-family;
  }
}

h1:not(.site-title) {
  font-size: 2.1rem;
  line-height: 1.190;
  margin-bottom: $vert-space;

  @include media($normal) {
    font-size: 2.1rem;
    line-height: 1.190;
  }

  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 2px;
    background-color: $strong_orange;
    margin-top: 0.5rem;
  }
}

h2 {
  font-size: 1.8rem;
  line-height: 1.194;
  //margin-bottom:0.5em;
  margin-bottom: $vert-space*1.5;
  position: relative;

  @include media($normal) {
    font-size: 1.8rem;
    line-height: 1.194;
  }

  &:after {
    content: '';
    display: block;
    width: 60px;
    height: 2px;
    background-color: $strong_orange;
    margin-top: 0.25rem;
    //position: absolute;
    //bottom:-2px;
  }
}

h2, h1 {
  &.site-title,
  &.site-slogan {
    &:after {
      display: none;
    }
  }
}

.front main,
.front .postscript-first {
  h2 {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
  }
}

h3 {
  font-size: 1.6rem;
  line-height: 1.1875;
  margin-bottom: 0.5rem;

  @include media($normal) {
    font-size: 1.6rem;
    line-height: 1.1875;
  }
}

h3.block__title {

  //font-size:1.375em;

  @media screen and (min-width: 768px) {
    //text-align: center;
    //font-size: 1.556rem;
  }
}

h4 {
  font-size: 1.4rem;
  line-height: 1.214;
  margin-bottom: 0.5em;

  @include media($normal) {
    font-size: 1.4rem;
    line-height: 1.214;
  }
}

h5 {
  font-size: 1.2rem;
  line-height: 1.2083;
  margin-bottom: 0.5em;

  @include media($normal) {
    font-size: 1.2rem;
    line-height: 1.2083;
  }
}

h6 {
  font-size: 1.1rem;
  line-height: 1.1818;
  margin-bottom: 0.5em;

  @include media($normal) {
    font-size: 1.1rem;
    line-height: 1.1818;
  }
}

p {
  margin: 0 0 $vert-space;
}

main,
.postscript-first {
  .card-body,
  .card-description {
    p, address, pre, ul, ol {
      font-outer-wrappersize: $base-font-size;
      line-height: $base-line-height;

      &.smaller-text {
        font-size: 0.9rem;
        line-height: 1.45;

        p, li {
          font-size: 1em;
        }
      }

      &.larger-text {
        font-size: 1.2rem;
        line-height: 1.4583;

        p, li {
          font-size: 1em;
        }
      }

      @include media($normal) {
        // font-size:1.125rem;
        // line-height: 1.44444444444444;

        &.smaller-text {
          font-size: 0.9rem;
          line-height: 1.45;
        }
        &.larger-text {
          font-size: 1.2rem;
          line-height: 1.4583;
        }
      }
    }

    .caption {
      display: block;
      line-height: 1.5em;
      text-align: left;

      p {
        font-size: 0.8em;
        line-height: 1.5;

        @include media($normal) {
          font-size: 0.8rem;
          line-height: 1.5;
        }
      }
    }
  }
}

b, strong {
  font-weight: 700;
}

a, a:link, a:active, a:visited {
  color: $action-default;
  text-decoration: none;

  &.active {
    color: $action-active;
    text-decoration: none;
  }

  &:hover,
  h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
    color: $action-hover;
    text-decoration: none;
  }

  &:not([href]) {
    color: inherit;
  }
}

.date,
.card-date {
  font-size: 0.7rem;
  font-style: normal;
}

em, i {
  font-style: italic;
}

sup, sub {
  font-size: smaller;

  &:before {
    content: "";
    margin-left: 0.25em;
  }
}

sup {
  vertical-align: super;
  top: 5px;
}

sub {
  vertical-align: sub;
}

blockquote {
  position: relative;
  background: rgba(255, 201, 20, 0.2);
  max-width: 900px;
  padding: 30px;
  font-size: 1.5rem;
  line-height: 1.43333;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 48px;
    left: -16px;
    background: url($open-quote) no-repeat scroll 50% 50%;
    width: 28px;
    height: 21px;

  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 28px;
    right: -16px;
    background: url($close-quote) no-repeat scroll 50% 50%;
    width: 28px;
    height: 21px;
  }
}

.chosen-container {
	width:100% !important;
	display:block;
	&.chosen-container-active {
		outline:none;
		&.chosen-with-drop {
			.chosen-single {
				div {
					b {
						transform:rotate(180deg);
					}
				}
				background:white;
				box-shadow:none;
			}
		}
	}
	&.chosen-with-drop.chosen-container-active {
		.chosen-single {
			height: 44px;
			margin-bottom: -2px;
		}
	}
	.chosen-single {
		height:42px;
		line-height:42px;
		border:1px solid $japanese_indigo;
		border-radius:21px;
		overflow:visible;
		background:white;
		box-shadow:none;
		padding-left:16px;
		span {
			margin-right:42px;
			font: 0.7rem/1.4285 $base-font-family;
			color: $stormcloud;
			margin-top: 10px;
			padding-left: 10px;
		}
	}
	.chosen-choices {
		//background:none;
		padding:6px 12px;
		li input.chosen-search-input.default {
			font: 0.7rem/1.4285 $base-font-family;
			color: $stormcloud;
			padding-left: 10px;
		}
	}
	.chosen-drop {
		box-shadow:none;
		z-index: 1021;
		.chosen-search {
			input {
				padding:8px 11px;
				font-size:1rem;
			}
		}
		ul.chosen-results {
			li {
				font-size:1rem;
				line-height:inherit;
				padding:4px 12px;
				&.highlighted {
					background-color:$strong_blue;
					background-image:none;
					color:$white;
				}
			}
		}
	}
	&.chosen-container-single {
		.chosen-search {
			input[type=text] {
				background:none !important;
			}
		}
		.chosen-single {
			div {
				top:-2px;
				width:43px;
				b {
					width:44px;
					height:44px;
					background: url($arrow-white-filter-down) no-repeat center center $strong_blue !important;
					background-size:50% 50% !important;
					transition:250ms ease all;
					border-radius:50%;
					position:relative;
					z-index:1020;
				}
				// b:after {
				// 	width:42px;
				// 	background:$arrow-white-filter-down no-repeat center center $strong_blue;
				// 	background-size:50% 50% !important;
				// 	transition:250ms ease all;
				// 	border-radius:50%;
				// 	height:42px;
				// 	display: inline-block;
				// 	content:"";
				// }
			}
		}
		.chosen-drop {
			background-color:white;
		}
	}
	&.chosen-container-multi {
		.chosen-choices {
			border:1px solid $japanese_indigo;
			border-radius:21px;
			height:42px;
			overflow:visible;
			position:relative;	
			&:after {
				content:"";
				height:44px;
				width:44px;
				display:inline-block;
				background: url($arrow-white-filter-down) no-repeat center center / 50% 50% $strong_blue;
				border-radius:50%;
				top:-2px;
				right:-1px;
				position: absolute;
			}
		}
		.chosen-drop {
			box-shadow:none;
			right: 20px;
			width: calc(100% - 35px);
		}
	}
}

.highcharts-container {
	.highcharts-null-point {
		cursor:default;
		stroke:#D1D1D1;
	}
}

.highcharts-credits {
  display: none;
}

#map-country-wrapper {
	padding: 20px;
  background: white;
	.highcharts-container {
		.highcharts-null-point {
			stroke:#AACB90;
		}
	}
}
.highcharts-legend-item:hover text {
  fill: $strong_blue !important;
}
#container-matrix .highcharts-legend .highcharts-crosshair {
	display: none;
}

.overview-tab-content.tab-partners,
.tab-content.intro-content,
.block-espen-country-endemicity-map,
.block-espen-country-coendemicity-map {
	position: relative;
}

a.how-to-use.photoswipe {
	display: block;
	position: absolute;
	right: $horz-space;
	top: $vert-space;
	.tab-content.intro-content & {
		// top: -2.25*$vert-space;
		position: relative;
		float: right;
		top: 0;
		right: 0;
    margin-bottom: 20px;
	}
	font:$nav-base-font;
	font-size: 1rem;
	color: white;
	text-transform: uppercase;
	// .country-content & {
	// 	color: $strong_blue;
	// }
	.tab-partners & {
		// color: $strong_blue;
		position: relative;
		float: right;
		top: 0;
		right: 0;
    margin: 20px 20px 20px 0;
		&:after {
		}
	}
	&:after {
		content: '?';
		text-align: center;
		display: block;
		width: $horz-space;
		height: $vert-space;
		border-radius: 50%;
		background-color: white;
		color: $strong_blue;
		float: right;
		margin-left: $horz-space/4;
		padding-left: $horz-space/10;
		.country-content & {
			// background-color: $strong_blue;
			// color: white;
		}
		// .tab-partners & {
		// 	background-color: $strong_blue;
		// 	color: white;
		// }
	}
	&:hover {
		color: $mikado_yellow;
		&:after {
			color: $primary;
			background-color: $mikado_yellow;
		}
	}
}

.highcharts-tooltip.legend>span {
  font-size: 18px!important;
  .title {
    font-size: 20px;
    font-weight: bold;
  }
}
.highcharts-point.zone-0 {
  fill: #f7a35c!important;
}

// ESPEN countries landing page
#map-africa-wrapper {
  @media(min-width:$narrow) {
    @include span-columns(9);
  }
  height: 90vh;
  //width: 100%;
  //.highcharts-legend-item {
  //  text {
  //    font-size: 20px;
  //  }
  //}
}

#map-africa-legend {
	@media(min-width:$narrow) {
		@include span-columns(3);
	}
	background: #F7F7F8;
	padding: 20px;
	ul {
		padding-left: 30px;
		list-style-type: none;
		margin-bottom: 0;
		li {
			position: relative;
			&:before {
				content: "";
				display: inline-block;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				position: absolute;
				left: -30px;
				top: 4px;
			}
			&.number-0:before {background: #FFFFFF;}
			&.number-1:before {background: #E3E0F7;}
			&.number-2:before {background: #9A8FE2;}
			&.number-3:before {background: #5E4CD0;}
			&.number-4:before {background: #2F2283;}
			&.number-5:before {background: #17113F;}
		}
	}
}

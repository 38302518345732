.i18n-en .site-title a { background:url($logo-en) no-repeat scroll 0 0 transparent; }
//.i18n-fr .site-title a { background:$logo-fr no-repeat scroll 0 0 transparent; }
//.i18n-pt .site-title a { background:$logo-pt no-repeat scroll 0 0 transparent; }

#site-header { 
	//background-color:$header-bg-color;
		&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
	}
	//
	// /* Change .header-outer-wrapper to 
	// .header-inner-wrapper, main-nav-wrapper
	// to make the menu a band in it's own right. */
	//
	
	.main-nav-wrapper .main-navigation {
		@include outer-container($site-max-width);
		padding: 0 10px;
	}
	
	.block-search {
			float:right;
			margin-top: 29px;
			@include media(420px) {
				margin-top: auto;
			}
			@include media(880px) {
				height:10rem;
				height:auto;
			}
			h2 {
				font-family:$base-font-family;
				font-size:1rem;
				color:$primary;
				float:left;
				line-height:1.5;
				margin-top:1rem;
				margin-right:0.8rem;
				cursor:pointer;
				display:none;
				@include media(880px) {
					margin-right:2.5rem;
				}
			}
			.form-wrapper {
				display:none;
				@include media(392px) {
					display:block;
				}
			}
			.form-item-search-block-form {
				margin-top:0;
				margin-right:0.8rem;
				margin-bottom:0;
				margin-left: 0.8rem;
				@include media(880px) {
					margin-bottom:3rem;
				}
				@include media($wide) {
					margin-bottom:3rem;
				}
				float:left;
				input.form-text {
					width:158px;
					height:42px;
					background-color:$header-search-field-bg-color;
					color:$header-search-field-text-color;
					box-shadow:$header-search-field-box-shadow;
					border:$header-search-field-border;
					border-radius:$header-search-field-border-radius;
					font:$header-search-field-font;
					padding:0.4rem 0.8rem;
					&::placeholder {
						color: $japanese_indigo;
					}
					@include media(434px) {
						width: 10rem;
					}
				}
			}
			.form-actions {
				float:left;
				margin-top:0.8rem;
				position: relative;
				@include media(880px) {
					margin-bottom:5rem;
				}
				input.form-submit {
					background: transparent url($search) no-repeat scroll 0 0;
					background-size:14px;
					width:14px;
					height:14px;
					text-indent:-999em;
					overflow:hidden;
					padding:0;
					position:absolute;
					right:30px;
					top: -2px;
					border-radius: 0;
				}
			}
		}
	
	.header-inner-wrapper {
		position:relative;
		@include outer-container($site-max-width);
		
		display: flex;
    flex-flow: wrap;
		
		@include media(880px) {
			display: block;
		}
		
		.wrapper-site-branding {
			order: 2;
			width: 100%;
			
			@include media(880px) {
				width: auto;
			}

			.site-branding {
				position:relative;
				//margin:1.5rem 0.5rem 0;
				margin:0 0.5rem $vert-space;
				@include media(880px) {
					margin:1rem 1.5rem 0 1.5rem;
					top:0;
				}
				@include clearfix;
				.site-title {
					float:left;
					//position:absolute;
					//left:0;
					//top:2.5rem;
					margin: 0; // 45px 0 0;
					
					@include media(880px) {
						margin: 0 0 0.67em 0;
					}
					
					a {
						display:block;
						text-indent:-999em;
						overflow:hidden;
						width:107px;
						height:135px;
						@include media(880px) {
							background-size:contain;
							width:322px;
							height:135px;
						}
					}
				}
				.site-slogan {
					background:url($logo-who) no-repeat scroll 0 0 transparent;
					background-size:contain;
					text-indent:-999em;
					overflow:hidden;
					width:148px;
					height:60px;
					float:right;
					display: none;
					@include media(415px) {
						display: block;
						//margin-top: 30px;
						//margin-right: 110px;				
						margin: 75px 100px 0 0
					}
					@include media(880px) {
						background-size:contain;
						width:200px;
						height:81px;
						// margin-top: 30px;
						// margin-right: 0;
						margin: 55px 0 0;
					}
					//position:absolute;
					//left:235px;
					//top:2.5rem;
				}
			}
		}

		.wrapper-region-header {
			order: 1;
	    width: 100%;
	    padding: 1rem 10px 0.5rem;
		
			@include media(880px) {
				position: absolute;
				right: 10px;
				top: 0;
				margin: 0;
				padding: 0;
				width: auto;
			}
			
			.block-gtranslate {
				float: left;
				margin-top: 29px;
				@include media(420px) {
					float: right;
					margin-top: auto;
				}
				// position: absolute;
				// right: 10px;
		    // top: 0;
				// margin: 0;
				// @include media(880px) {
				// 	//top: -15px;
				// }
				
				select {
					font: $header-menu-font;
					background-size: auto 40px;
					padding: 10px 20px;
					height: 40px;
					line-height: 1rem !important;
					background-position-x: calc(100% + 1px);
					padding-right: 45px;
					border-color: $japanese_indigo;
				}
			}
			
			.block-menu {
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				padding: 8px 20px;
				background: $white_smoke;
				line-height: 130%;
				font-size: 0.7rem;
				@include media(420px) {
					position: relative;
					padding: 0;
					float: right;
					margin-right:0.5rem;
					width: auto;
					background: none;
					line-height: inherit;
					font-size: inherit;
				}
				@media (min-width:420px) and (max-width:880px) {
					clear: both;
				}
				// margin-top:1rem;
				// position: absolute;
		    // right: 180px;
		    // top: 0;
				// margin: 0;
				// @include media(880px) {
				// 	//top: -15px;
				// }
				
				ul.menu {
					margin: 0;
					padding: 0;
					float: right;
				}
				li {
					display:inline-block;
					margin:0 0.8rem 0 0;
				}
				
				a {
					font:$header-menu-font;
					color:$header-menu-link-color;
					
					&:hover {
						color:$header-menu-link-hover;
					}
				}
			}
		}
		
	}
	
	.block-locale {
		clear:both;
		display:none;
	}
}

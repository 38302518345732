.node-type-page.path-countries {
	.view-countries {
		@media(min-width:740px) {
			width:calc(50% - 10px);
		}
		@media(min-width:1200px) {
			width:calc(33% - 10px);
		}
	}
	.map-africa-wrapper {
		// @include media(880px) {
		// 	width:66.666%;
		// 	float:right;
		// }
	}
	#block-views-countries-block {
		// @include media(880px) {
		// 	width:33.333%;
		// 	float:left;
		// }
		.view-content {
			ul {
				&:before {
					content:'';
					background-color:#DADEE0;
					position:absolute;
					display:block;
					width:1px;
					height:100%;
					height:calc(100% - 2rem);
					left: 50%;
					top:1rem;
					@include media($narrow) {
						left:33.333%;
					}
				}
				position:relative;
				list-style:none;
				padding:1rem 1.2rem;
				margin:0;
				@include media(880px) {
					margin:0 1.2rem 0 0;
				}
				background-color:$grey;
				column-count:2;
				@include media($narrow) {
					column-count:3;
				}
				&:after {
					@include media($narrow) {
						content:'';
						background-color:#DADEE0;
						position:absolute;
						display:block;
						width:1px;
						height:100%;
						height:calc(100% - 2rem);
						left:65%;
						top:1rem;
					}
				}
				li {
					word-wrap:break-word;
					margin-bottom:$vert-space/4;
				}
			}
		}
	}
}

body.lhs-tabs-node {
  .content-hero {
		padding-top: #{$gutt};
		.view-display-id-title {
			.views-row {
				display: flex;
				margin: 0 calc(#{$gutt}/2);
				h1 {
					margin: 0;
					&:after {
						display: none;
					}
				}
				.card-country-flag {
					margin-right: 20px;
					display: flex;
					justify-content: center;
					flex-direction: column;
				}
			}
		}
	}
	article > h1 {
		display: none;
	}
  &.one-sidebar.sidebar-first {
    main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;}
      aside {
        &#sidebar-first {
          width: 100%;
          margin: 0 calc(#{$gutt}/2) 0 calc(#{$gutt}/2);
          @include media($normal) {
            width: calc(100%/4);
            margin: 0 0 0 calc(#{$gutt}/2);
          }
        }
      }

      section#content {
        width: 100%;
        margin: 0;
				background: $antiflash_white;
				padding: 30px;
        @include media($normal) {
          width: calc(100%/4*3 - #{$gutt});
          margin-right: calc(#{$gutt}/2);
        }
      }
     }
  }

	&.one-sidebar.sidebar-second {
    main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;}
      aside {
        &#sidebar-second {
          width: 100%;
          margin: calc(#{$gutt} / 2);
          @include media($narrow) {
            width: calc(100%/4 -  #{$gutt});
          }
        }
      }

      section#content {
        width: 100%;
        margin: 0 0 $vert-space;
        @include media($narrow) {
          width: calc(100%/4*3 -  #{$gutt});
        }
      }
    }
  }

	&.two-sidebars {
    main {
      .primary {
        width:100%;
      }
      .outer-wrapper {display:flex;flex-wrap:wrap;}
      aside {
        &#sidebar-first {
          width: 100%;
					margin: 0 calc(#{$gutt}/2) 0 calc(#{$gutt}/2);

          @include media($normal) {
            width: calc(100%/4);
						margin: 0 0 0 calc(#{$gutt}/2);
          }
        }
        &#sidebar-second {
          width: 100%;
          margin: 0;
          padding: calc(#{$gutt});
					background: $antiflash_white;
          @include media($normal) {
            width: calc(100%/4 -  #{$gutt}/2);
          }
        }
      }

      section#content {
        width: 100%;
        margin: 0;
				padding: #{$gutt};
				background: $antiflash_white;
        @include media($normal) {
          width: calc(100%/2 -  #{$gutt});
					padding: #{$gutt} 0 #{$gutt} #{$gutt};
        }
        #block-espen-country-blocks-espen-country-node-content,
        #block-espen-region-blocks-espen-region-node-content {
          .country-content {
            display: none;
          }
        }
      }
    }
  }
	#block-espen-disease-stage-blocks-espen-disease-node-content,
	#block-espen-disease-stage-blocks-espen-stage-node-content {
		.country-content {
			display: none;
		}
	}
  .lhs-tabs {
    h3.country-tab {
      display: none;
      cursor: pointer;
      @include media($narrow) {
        display: block;
      }
    }
    select {
      @include media($normal) {
        display: none;
      }
    }
  }
  section#content {
    @include media($narrow) {
      padding-left: 0;
    }
  }
  &.node-type-type {
    h3.country-tab.country-tab-data {
      display: none !important;
    }
  }
}

// Tabs within each disease tab
#block-espen-country-blocks-espen-country-node-content,
#block-espen-region-blocks-espen-region-node-content,
#block-espen-country-blocks-espen-country-node-overview-tabs {
	.country-content-tabs,
	.country-overview-tabs {
		border-bottom: 2px solid $strong_orange;
		display: flex;
		margin-bottom: #{$gutt};
    overflow-x: auto;
    -ms-overflow-style: none;
		.country-content-tab,
		.country-overview-tab {
			background: $white;
			color: #586570;
			padding: 0.4rem 1rem;
			border-radius: 40px;
			margin-right: 10px;
			margin-bottom: 17px;
			cursor: pointer;
      white-space: nowrap;
      font-size: 1rem;
			&.active {
				background: $strong_orange;
				color: $white;
        &:after {
          background: $strong_orange;
        }
        &:hover {
  				background: $strong_orange;
          color: white;
          &:after {
            background: $strong_orange;
          }
  			}
			}
			&:hover {
				background: $mikado_yellow;
        color: $primary;
        &:after {
          background: $mikado_yellow;
        }
			}
			&:after {
				content: "";
				display: block;
				height: 17px;
				width: 2px;
				background: white;
				margin-bottom: -25px;
		    margin-top: 8px;
		    margin-left: auto;
		    margin-right: auto;
			}
		}
	}
	.data-content {
		display: none;
	}
  table caption {
    background: $white;
    text-align: left;
    color: $primary;
    padding-left: 0;
    padding-top: 0;
    font-size: 1rem;
    font-weight: 600;
  }
}

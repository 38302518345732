.view.view-data-download {
  position: relative;
  a.data-dictionary {
    display: block;
    position: absolute;
    right: $horz-space;
    top: $vert-space;
    font:$nav-base-font;
    font-size: 1rem;
    color: $strong_blue;
    &:after {
      content: '';
      display: block;
      background: url($ico-download) no-repeat 50% 50%;
      width: 30px;
      height: 20px;
      color: $strong_blue;
      float: right;
      margin-left: $horz-space/4;
    }
    &:hover {
      color: $mikado_yellow;
      &:after {
        filter: invert(73%) sepia(70%) saturate(541%) hue-rotate(355deg) brightness(100%) contrast(102%);
      }
    }
  }
}

/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;

	.card-qa-answer {
		display: none;
		padding: 10px;	
		box-sizing: border-box;
 
		:last-child {
			margin-bottom: 0;
		}
	}
	
	.card-qa-question {
		font: 1.2rem $title-font-family;
		text-decoration: none;
		color: $primary;
		padding: 11px 0 11px 46px;
		line-height: 1.2083;
		display: block;
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		background: url($ico-expand-blue) no-repeat scroll 0 50% / 24px 24px transparent;

		&.active {
			color: $strong_blue;
			background: url($ico-collapse-blue) no-repeat scroll 0 50% / 24px 24px transparent;
			&:hover {
				background: url($ico-collapse-yellow) no-repeat scroll 0 50% / 24px 24px transparent;
			}
		}
		
		&:hover {
			color: $mikado_yellow;
			background: url($ico-expand-yellow) no-repeat scroll 0 50% / 24px 24px transparent;
			
		}
	}
	
	/*.ico-collapse, .ico-expand {
		width: 20px;
		height: 20px;
		float:left;
		margin-right: 20px;
	}

	.ico-collapse {
		
	}
	.ico-expand {
		
	}*/

}
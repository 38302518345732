#block-views-sections-hero {
	max-width: $site-max-width;
	margin-left: auto;
	margin-right: auto;
	padding: 0 10px;
	
	// h1.page-title-hero {
	// 	&:after {
	// 		content:'';
	// 		display:block;
	// 		width:90px;
	// 		height:2px;
	// 		background-color:$strong_orange;
	// 		margin-top:0.5rem;
	// 	}
	// }
	
	.view-sections {
		margin-bottom: 0;
	}
	.section-row {
		&.text-expand {
			.section-inner {
				overflow: visible;
				.card-body {
					max-width: 1220px;
					position: relative;
					overflow:visible;				
    			padding-right: 30px;
					//box-shadow: 0px 0px 10px rgba(44, 63, 77, 0.2);
					.content-block-wrapper {
						//border-radius: 10px;
						//padding: 30px 30px 40px;
						.expand {
						    box-shadow: 0px 0px 10px rgba(44,63,77,0.2);
						    padding: 30px 30px 40px;
								border-radius: 10px;
						}
						span.ellip {
							display:none;
						}
					}
					a.morelink {
						position: absolute;
						right: 0; //-30px;
						bottom: -24px;
						display: block;
						padding: 15px 55px 9px 35px;
						background: $strong_blue url($ico-expand-white) no-repeat scroll;
						background-position: right 32px center;
						border-radius: 40px;
						font: 1.2rem $subtitle-font-family;;
						color: white;
						text-transform: uppercase;
						&.less {
							background: $strong_blue url($ico-collapse-white) no-repeat scroll;
							background-position: right 32px center;
							&:hover {
								background: $mikado_yellow url($ico-collapse-indigo) no-repeat scroll;
								background-position: right 32px center;
							}
						}
						&:hover {
							background: $mikado_yellow url($ico-expand-indigo) no-repeat scroll;
							background-position: right 32px center;
							color: $primary;
						}
					}
				}
			}
		}
		&.text-expand.expand-image {
			.section-inner {
				overflow: visible;
				.card-original { 
					float:left;
					img {
						border-radius:50%;
						max-width:220px;
					}
				}
				.card-body {
					left:50px;
					top:25px;
					max-width:1160px;
				}
			}
		}
	}
}
.item-list ul.pager {
  font: 1.111rem/1.45 $subtitle-regular-font-family;
  position: relative;
  margin: 10px 0;
  @media screen and (max-width: $narrow) {
    font-size: 0.750em;
  }

  a {
    color: $strong_blue;

    &:hover {
      color: $primary;
    }
  }

  li.pager-next {
    a {
      background: $strong-blue url($arrow-white-right) no-repeat scroll 50% 50%;
      text-indent: -9999px;
      display: inline-block;
      overflow: hidden;
      height: 20px;
      width: 20px;
      padding: 18px;
      border-radius: 50%;
      margin-bottom: -10px;
    }
  }

  li.pager-last {
    a {
      background: $strong-blue url($arrow-white-last) no-repeat scroll 50% 50% / 20px;
      text-indent: -9999px;
      display: inline-block;
      overflow: hidden;
      height: 20px;
      width: 20px;
      padding: 18px;
      border-radius: 50%;
      margin-bottom: -10px;
    }
  }

  li.pager-previous {
    a {
      background: $strong-blue url($arrow-white-left) no-repeat scroll 50% 50%;
      text-indent: -9999px;
      display: inline-block;
      overflow: hidden;
      height: 20px;
      width: 20px;
      padding: 18px;
      border-radius: 50%;
      margin-bottom: -10px;
    }
  }

  li.pager-first {
    a {
      background: $strong-blue url($arrow-white-first) no-repeat scroll 50% 50% / 20px;
      text-indent: -9999px;
      display: inline-block;
      overflow: hidden;
      height: 20px;
      width: 20px;
      padding: 18px;
      border-radius: 50%;
      margin-bottom: -10px;
    }
  }

  li.pager-item {
    display: inline-block;
    margin: 0 3px;
  }

  li.pager-current {
    color: $primary;
  }

  li {
    &.pager-first,
    &.pager-previous,
    &.pager-next,
    &.pager-last {
      a:hover {
        background-color: $mikado_yellow;
      }
    }
  }
}

.page-item.active .page-link {
  background-color: $strong-blue !important;
  border-color: $strong-blue !important;
}

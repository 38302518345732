#breadcrumb {
	background:$breadcrumb-bg-color;
	clear: both;
	padding:5px 0;

	@media(max-width:$narrow) {
		padding:$vert-space/4 0;
		//margin-top: $vert-space;
	}
	.breadcrumb {
		color:$breadcrumb-active-color;
		display:inline-block;
		font:$breadcrumb-font;
		line-height: 1.5;
		margin:$vert-space/4 0;

		padding:0 $horz-space/2;

		@media(max-width:$narrow) {
			padding:0 $vert-space/2;
		}
		span {

			&:after {
				color:$breadcrumb-div-color;
				content:'';
				//margin-left:$horz-space/2;
				//margin-right:$horz-space/2;
			}

			a {
				border:none;
				color:$breadcrumb-action-color;
				background: url($div-breadcrumbs) no-repeat 100% 50%;
				background-size:12.5% 50%;
				padding-right:$horz-space*1.25;
				&:hover {
					color:$breadcrumb-hover-color;
				}
			}

			&.first a {
				padding-left: 0;
			}

			&:not(.first) {
				padding-left: 15px;

				&:after {
					content:normal;
				}
			}
		}
	}
}

// .block {
// 	.block-inner {
// 		& > h2:after {
// 			content:'';
// 			display:block;
// 			width:60px;
// 			height:2px;
// 			background-color:$strong_orange;
// 			margin-top:0.2rem;
// 			position: absolute;
// 			bottom:-2px;
// 		}
// 	}
// }
.front #sidebar-second #block-views-sections-sidebar {
  margin-bottom: 0;

  .block-inner {
    .view-sections {
      margin-bottom: 0;

      .views-row {
        padding-top: 0;
        margin-bottom: 0;

        .card-body {
          font-size: 1.2rem;
          font-weight: 500;
          margin-bottom: 0;

          &:after {
            content: '';
            display: block;
            width: 60px;
            height: 2px;
            background-color: #00B1FC;
            margin-top: $vert-space;
          }
        }
      }
    }
  }
}

.front #sidebar-second #block-views-maps-total-block {
  .block-inner {
    .view-maps-total {
      padding: 0 1.2rem 1rem;

      h3 {
        margin-bottom: 0;
        line-height: 1.125;
      }

      span {
        display: block;
        font-size: 0.8rem;
        font-style: italic;
      }
    }
  }
}

#block-views-countries-jump, #block-views-diseases-browse-block {
  background-color: $grey;
  padding: 1rem 1.2rem;
  margin-bottom: $vert-space;
}

#block-views-diseases-browse-block {
  .views-row {
    a {
      font-size: 1.2rem;
    }

    margin-bottom: $vert-space/4;
  }
}

.front main section#content .content-main,
.front main section#content .content-additional {
  padding: 0;
}

.card-body {
  margin-bottom: $vert_space;
}

.node-type-page.path-diseases,
.node-type-page.path-regions {
  .view-diseases, .view-regions {
    .view-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .views-row {
        width: 100%;
        @include media(880px) {
          width: calc(1 / 4 * 100% - (1 - 1 / 4) * 20px);
        }

        a.aggregated-link {
          display: block;
          background-color: $strong_blue;
          text-align: center;
          margin: 0 $horz_space/2 $vert_space;
          transition: 1s ease background-color;

          .aggregated-icon {
            position: relative;
            background-color: $bright_blue;
            height: 12rem;
            overflow: hidden;

            svg {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          h3 {
            height: 3em;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1.125;
            padding: 1.8rem 0.5rem 0;
            color: $white;
          }

          .aggregated-maps {
            padding: 1.5rem 1.2rem 2rem;
            @media(min-width: 1445px) {
              padding: 0.5rem 1.2rem 2rem;
            }
            color: $white;
            display: flex;
            justify-content: center;

            .view {
              padding-left: 6px;
            }
          }

          &:hover {
            background-color: $faded_blue;
          }
        }
      }
    }
  }
}

// Browse maps & data by program stage
.node-type-page.path-program-stages {
	.view-types {
		.views-row {
			position: relative;
			a {
				display: block;
				background: $antiflash_white;
				margin-bottom:30px;
				padding:40px 40px 30px 40px;
				overflow: auto;
				h3 {
					font: 2rem $subtitle-font-family;
					text-transform: uppercase;
				}
				.aggregated-maps,
				.card-body-et,
				.card-summary {
					color: $primary;
					@include media($narrow) {
						margin-left: 220px;
					}
				}
				.aggregated-maps {
					margin-bottom: 16px;
				}
				.card-icon {
					text-align: center;
					margin: 20px 0;
					@include media($narrow) {
						float: left;
						padding: 23px 65px 23px 20px;
						margin: 0;
					}
				}
				&:hover {
					background: $azureish_white;
				}
			}
			&:before {
				content: "";
				display: block;
				height: 80px;
				width: 80px;
				background: white url($arrow-yellow-down) no-repeat scroll 50% 50%;
				border-radius: 50%;
				position: absolute;
				top: -55px;
				left: calc(50% - 40px);
				@include media($narrow) {
					left: 89px;
				}
			}
			&.views-row-first:before {
				display: none;
			}
		}
	}
}

.view-footer-sections {
  .view-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .views-row {
      flex: 0 100%;
      @include media(880px) {
        flex: 0 50%;
      }

      .card-title {
        h2 {
          &:after {
            // content:'';
            // display:block;
            width: 240px;
            // height:2px;
            // background-color:$bright_blue;
            // margin-top:0.5rem;
          }
        }
      }
    }
  }
}

.front, .node-type-page.path-countries {
  .map-africa-wrapper {
    height: 480px;
    margin-bottom: $vert_space;
    @include media(880px) {
      height: 640px;
    }
    @include media(1200px) {
      height: 720px;
    }
    @include media(1600px) {
      height: 960px;
    }
  }
}

.not-front {
  .postscript-first {
    clear: both;
    overflow: visible; // hidden in remora for some reason...
  }
}

.flex-title h2 {
  display: flex;
  align-items: center;
  flex-flow: column wrap;
}

.block-row-padding {
  padding: $vert-space*2.5 0;
}

.view-id-diseases.view-display-id-home {
	.view-header .view-content > div {
		width: 100%;
	}
	.view-content {
		display: flex;
		flex-wrap: wrap;
		.views-row {
			text-align: center;
			flex-grow: 1;
	    flex-basis: 0;
			margin:15px;
			a {
				display: block;
				.card-icon {
					.aggregated-icon {
						display: inline-block;
						height: 130px;
						width: 130px;
						background: $strong_blue;
						border-radius: 50%;
						text-align: center;
						svg {
							height: 100%;
							&:first-child {
								display: none;
							}
						}
						// Icon sizes
						&.ONC-icon svg {
							width: 50px;
						}
						&.LF-icon svg,
						&.SCH-icon svg,
						&.STH-icon svg {
							width: 80px;
						}
						&.LOA-icon svg,
						&.TH-icon svg,
						&.Coendemicity-icon svg {
							width: 70px;
						}
					}
				}
				.card-views-conditional {
					display: inline-block;
					margin-top: 8px;
					h5 {
						font-family: $subtitle-font-family;
						text-transform: uppercase;
						border-bottom: 2px solid $primary;
						background: url($arrow-indigo-right) no-repeat scroll 100% 40% / auto 14px;
						padding-right: 16px;
						padding-left: 2px;
					}
				}
				&:hover {
					.card-icon .aggregated-icon {
						background: $mikado_yellow;
						svg {
							&:first-child {
								display: inline-block;
							}
							&:nth-child(2) {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

.node-type-country,
.node-type-region {
	.lhs-tabs {
		select {
			background: #FAEFE8 url($arrow-filter-down-orange) no-repeat 100% 50% / auto 38px;
			border: 1px solid #faefe8;
		}
	}
}

#block-views-jump-country-region-cx {
  padding: 0 !important;
  background-color: transparent !important;
}

// DOWNLOAD MAP/DATA TABLE STYLES
.download-codebook {
  cursor: pointer;
  float: right;
  font-weight: normal;

  &::after {
    content: "";
    background: url($ico-download) no-repeat 50% 50%;
    display: inline-block;
    width: 30px;
    height: 20px;
  }
}

a,
button {
  &.download {
    display: block;
    float: left;
    width: 63px;
    height: 49px;
    margin: 0 10px 0 0;
    padding: 0;
    overflow: hidden;
    text-indent: -9999px;
  }

  &.download-map {
		width: 30px;
    background: url($ico-download-pdf-blue) no-repeat 50% 50%;
    &:hover {
      background: url($ico-download-pdf-orange) no-repeat 50% 50%;
    }
  }

  &.download-data {
		width: 22px;
    background: url($ico-download-csv-blue) no-repeat 50% 50%;
    &:hover {
      background: url($ico-download-csv-orange) no-repeat 50% 50%;
    }
  }

  &.download-map-png {
		width: 30px;
    background: url($ico-download-png-blue) no-repeat 50% 50%;
    &:hover {
      background: url($ico-download-png-orange) no-repeat 50% 50%;
    }
  }

  &.download-carto {
    &:before {
      content: "";
      display: inline-block;
      height: 30px;
      width: 30px;
      background: url($ico-download-blue) no-repeat 50% 50%;
      vertical-align: bottom;
    }

    &:hover:before {
      background: url($ico-download-indigo) no-repeat 50% 50%;
    }
  }
}

td.views-field-field-map-image-landscape,
td.views-field-uri-3,
td.views-field-field-map-image {
	min-width: 175px;
	span.photoswipe-gallery {
		float: left;
	}
	a.photoswipe {
		display: block;
		border-radius: 5px;
		cursor: pointer;
		transition: 0.3s;
		height: 49px;
		width: 50px;
		margin: 0 10px 0 0;
		background: url($ico-preview) no-repeat 50% 50%;
		&:hover {
			background: url($ico-preview-hover) no-repeat 50% 50%;
		}
		img {
			width: 0;
		}
	}
}

.view.view-data-download {
  button.download {
    text-indent: initial;
    margin: 0;
    float: none;
    width: auto;
    margin-top: 20px;
    padding: 0.5rem 1rem 0.25rem;
    margin-right: calc(100% - 154px);
  }
}

// Hide form body for authenticated users
body.logged-in.node-type-form {
  article .card-body {
    display: none;
  }
}

body.node-type-application {
  .view-application-history {
    margin-bottom: 20px;

    .view-header {
      padding-top: 0;
    }
  }

  .view-application-submission {
    .views-row {
      background: $antiflash_white;
      padding: 30px 40px;

      h2.webform-page {
        font-size: 1.6rem;
      }
    }
  }
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: $button-bg;
  border: $button-border;
  border-radius: $button-border-radius;
  border: none;
  color: $button-text;
  cursor: pointer;
  display: inline-block;
  font: $button-font;
  padding: 0.5rem 1rem 0.25rem;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: $button-hover-bg;
    color:  $button-hover-text;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

a.button,
.button a {
	@include button-link-default();
  border-radius: $button-border-radius;
  border: none;
  cursor: pointer;
  display: inline-block;
  font: $button-link-font;
  padding-right: 36px;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-top: -3px;
  }
}
.side-row {
  a.button,
  .button a {
    padding: 0.55rem 2rem 0.4rem 1rem;
    font: 1.1rem/1.1818 $subtitle-font-family;
    font-weight: 500;
    border-radius: 20px;
    &:after {
      top: calc(50% - 12px);
    }
  }
}

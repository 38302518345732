.view-attachments,
.view-country-resources {
	
	// /* LAYOUT */
	ul { 
		padding:0;
		margin:0;
	}
	
	.views-row {
		padding: $vert-space/2 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;
		background:$antiflash_white;
		
		.file-icon {
			//float:left;
			//margin:0 $horz-space 0 0;
			//min-width:4rem;
			position: relative;
			.file-extension {
				padding:$vert-space*0.3 $horz-space/4;
				text-transform:uppercase;
				font-size:0.8em;
        line-height:1;
				text-align:center;
				//font-weight:bold;
				display:inline;
				margin-left: 54px;
				color:$japanese_indigo;
				&:before {
					content:"";
					height:40px;
					width:40px;
					display:inline-block;
					background:url($ico-download) no-repeat 50% 50% / 20px 20px $mikado_yellow;
					position: absolute;
					left:0;
				}
			}
			.file-size { 
        padding: 3px 5px;
				font-size:0.8em;			
				text-align:center;
				color:$japanese_indigo;
        //border:$secondary-border;
        border-top:none;
				display:inline;
				&:before {
					content:"|";
					font-size:1.4em;
					display:inline;
					height:16px;
					//width:3px;
					margin:0 12px 0 8px;
				}
			}
		}
		
		.file-name {
			overflow:hidden;
			padding:20px;
			color:$japanese_indigo;
			font-size:1.2em;
		}
		&:hover .file-name {
			color:$strong_blue;
		}
	}


	// /* FILE TYPE COLOURS */
  
  // .file-type-docx, .file-type-doc {
  //   .file-extension {
  //     background:$docx_color;
  //     color:white;
  //   }
  // }
  // .file-type-xlsx, .file-type-xls {
  //   .file-extension {
  //     background:$xlsx_color;
  //     color:white;
  //   }
  // }
  // .file-type-pptx, .file-type-ppt {
  //   .file-extension {
  //     background:$pptx_color;
  //     color:white;
  //   }
  // }
  // .file-type-pdf {
  //   .file-extension {
  //     background:$pdf_color;
  //     color:white;
  //   }
  // }
  // .file-type-rtf, .file-type-txt {
  //   .file-extension {
  //     background:$txt_color;
  //     color:white;
  //   }
  // }
  // .file-type-jpg, .file-type-png, .file-type-bmp, gif {
  //   .file-extension {
  //     background:$jpg_color;
  //     color:white;
  //   }
  // }
  // .file-type-zip, .file-type-rar, .file-type-7z {
  //   .file-extension { 
  //     background:$zip_color;
  //     color:white;
  //   }
  // }
} 


// RESPONSIVE STYLING

.view-attachments {
	.views-row {
		width:100%;
		float:left;

		@media (min-width:720px) {
			width:47%;
			margin-right:20px;
		}
		
		&:nth-child(2n+1){
			@media (min-width:720px) and (max-width:1199px) {
				clear:left;
			}
		}

		@media (min-width:1200px) {
			width:23%;
			margin-right:20px;
		}
		&:nth-child(4n+1){
		 	@media (min-width:1200px) {
				clear:left;
			}
		}
	}
}

.side-row .view-attachments .views-row {
	width:100%;
}

.one-sidebar,
.two-sidebars {
	.content-main,
	.content-additional {
		.view-attachments	.views-row {
			width:100%;
			@media (min-width:960px) {
				width:48%;
				margin-right:20px;
				&:nth-child(2n+1) {
					clear:left;
				}
				&:nth-child(2n) {
					margin-right:0;
				}
			}
			// @media (min-width:1600px) {
			// 	width:32%;
			// 	margin-right:20px;
			// 	&:nth-child(3n+1) {
			// 		clear:left;
			// 	}
			// 	&:nth-child(3n) {
			// 		margin-right:0;
			// 	}
			// }
		}
	}
}

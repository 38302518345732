.nav-pills .nav-link {
  background-color: $pale_orange !important;
  color: $japanese_indigo !important;
  border-radius: 4px 4px 0px 0px !important;
  padding: 10px 20px !important;
}
.nav-pills .nav-link.active {
  background-color: $strong_orange !important;
  color: white !important;
}

.nav-item {
  padding-left: 4px;
}

main {
	background-color:$white;

	#content { padding:0 $horz-space/2; }
}
.postscript-first {
	background-color:$main-page-bg;
	@include outer-container($site-max-width);
	.postscript_first {
		padding:0 $horz-space/2;
	}
}

.front main {/*display: none*/}

.outer-wrapper {
	@include outer-container($site-max-width);
}

hr {
	border:none;
	border-bottom:$primary-border;
	margin: 0 0 $vert-space;
}
p + hr,
ul + hr,
ol + hr,
address + hr,
pre + hr,
blockquote + hr {
	margin-top: $vert-space;
}


.caption {
	display: block;
	text-align: left;
	font-size: 0.8rem;

	p {
		line-height: 1.42857142857143;
		margin: 5px 0 1em;
		
		&:last-child {margin-bottom: 0;}
	}
}

body.page-title-hero article h1 {
  display: none;
}
h1#page-title {
	width: 100%;
	margin-left: 15px;
	margin-right: 15px;
}
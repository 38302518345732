@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,800);
/*
@font-face {
    font-family: 'DINNextLTProMediumCond';
    src: url('../fonts/DINNextLTProMediumCond.eot');
    src: url('../fonts/DINNextLTProMediumCond.eot') format('embedded-opentype'),
         url('../fonts/DINNextLTProMediumCond.woff2') format('woff2'),
         url('../fonts/DINNextLTProMediumCond.woff') format('woff'),
         url('../fonts/DINNextLTProMediumCond.ttf') format('truetype'),
         url('../fonts/DINNextLTProMediumCond.svg#DINNextLTProMediumCond') format('svg');
}
*/
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=48fde9bf-8bd6-4e29-9f1e-6cc722031d1e");
@font-face{
	font-family:"DIN Next W01_n5";
	src:url("../fonts/0b46a18d-fb34-4ef7-b5da-5902c510577a.eot?#iefix") format("eot")
}
@font-face{
	font-family:"DIN Next W01";
	src:url("../fonts/0b46a18d-fb34-4ef7-b5da-5902c510577a.eot?#iefix");
	src:url("../fonts/0b46a18d-fb34-4ef7-b5da-5902c510577a.eot?#iefix") format("eot"),url("../fonts/76b8cda6-0ded-4197-acab-e314297eb90f.woff2") format("woff2"),url("../fonts/2613a064-2962-44af-ae6f-3640e06e8dc4.woff") format("woff"),url("../fonts/2889b152-497e-4ea3-a90d-02854d136a8d.ttf") format("truetype"),url("../fonts/4f311da1-9ec6-4379-9621-b45be0e23abd.svg#4f311da1-9ec6-4379-9621-b45be0e23abd") format("svg");
	font-weight:500;
	font-style:normal;
}
@font-face{
	font-family:"DIN Next W01";
	src:url("../fonts/6fa09720-5b0e-48a5-8895-5af8af928c01.eot?#iefix");
	src:url("../fonts/6fa09720-5b0e-48a5-8895-5af8af928c01.eot?#iefix") format("eot"),url("../fonts/5dd9f4c4-cfde-415c-bb51-433569311de5.woff2") format("woff2"),url("../fonts/3b15daf5-d7fb-4099-93b9-db20fd26c2fe.woff") format("woff"),url("../fonts/16159505-c85d-44ba-ba2b-64f94e68ddb5.ttf") format("truetype"),url("../fonts/4f311da1-9ec6-4379-9621-b45be0e23abd.svg#4f311da1-9ec6-4379-9621-b45be0e23abd") format("svg");
	font-weight:400;
	font-style:normal;
}
@font-face{
	font-family:"DIN Next™ W01";
	src:url("../fonts/936930ba-a8da-4765-9e89-0f35cdff223f.eot?#iefix");
	src:url("../fonts/936930ba-a8da-4765-9e89-0f35cdff223f.eot?#iefix") format("eot"),url("../fonts/6b4d4830-9c7d-43d2-b5d3-c73f739561b9.woff2") format("woff2"),url("../fonts/ccf25ada-6d2a-4133-95dc-deb039e22999.woff") format("woff"),url("../fonts/126195b4-2fa0-4b95-b5cf-ad9bf10193f0.ttf") format("truetype"),url("../fonts/4f311da1-9ec6-4379-9621-b45be0e23abd.svg#4f311da1-9ec6-4379-9621-b45be0e23abd") format("svg");
	font-weight:400;
	font-style:normal;
}
@font-face{
	font-family:"DIN Next™ W01";
	src:url("../fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix");
	src:url("../fonts/fa5ed46b-792d-4f47-894e-fa587cf4e1cf.eot?#iefix") format("eot"),url("../fonts/03008527-67bd-478e-98e3-3b56dd9a6520.woff2") format("woff2"),url("../fonts/557f817d-9c25-4a23-baaf-a3cf84a7cd7c.woff") format("woff"),url("../fonts/92ed1479-1d40-4a94-8baf-6abd88b17afa.ttf") format("truetype"),url("../fonts/4f311da1-9ec6-4379-9621-b45be0e23abd.svg#4f311da1-9ec6-4379-9621-b45be0e23abd") format("svg");
	font-weight:700;
	font-style:normal;
}
@font-face{
	font-family:"DIN Next™ W01";
	src:url("../fonts/ef79833e-ca28-419f-898a-a96725a0e7b5.eot?#iefix");
	src:url("../fonts/ef79833e-ca28-419f-898a-a96725a0e7b5.eot?#iefix") format("eot"),url("../fonts/7324033f-ef94-4c54-8e88-377b447f0fa2.woff2") format("woff2"),url("../fonts/3efb3b3f-ac55-4ec0-8099-940870596c57.woff") format("woff"),url("../fonts/a6869823-bba8-457d-9f31-3b1cfe4c9bb9.ttf") format("truetype"),url("../fonts/4f311da1-9ec6-4379-9621-b45be0e23abd.svg#4f311da1-9ec6-4379-9621-b45be0e23abd") format("svg");
	font-weight:900;
	font-style:normal;
}

$base-font-family      				: 'DIN Next™ W01';//Condensed DIN
$title-font-family    				: 'DIN Next™ W01';//Condensed DIN
$subtitle-regular-font-family : 'DIN Next™ W01';//Condensed DIN
$subtitle-font-family 	 			: 'DIN Next W01';


$base-font-size						: 20px;
$base-line-height					: 1.45;

$base-font-size-normal		: 20px;

$header-menu-font 				: 0.8rem/0.95 $base-font-family;
$header-search-field-font : 0.8rem/1.286 $base-font-family;

$nav-base-font        	 	: 500 1.2rem $subtitle-font-family;
$nav-base-font-smaller 		: 1rem/1.45 $base-font-family;
$nav-sub-base-font        : 1rem/1.45 $base-font-family;

$breadcrumb-font 					: 0.8rem $title-font-family;

$section-row-title-font 	: 600s 2.111rem/1.25 $title-font-family; // /* Nugget title */
$section-row-title-font-smaller 	: 500 2rem/1.25 $title-font-family; // /* Nugget title */ 

$side-row-title-font 			: 1.4rem/1.2142 $title-font-family; // /* Sidebar nugget title */

$card-title-font-family		: $base-font-family;
$card-title-font        	: normal 1.2rem/1.2083 $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: normal 1.125rem/1 $base-font-family;
$card-feed-link-font			: bold 0.8125rem/1.46153846153846 $base-font-family;

$button-link-font 				: 500 1.2rem/1.2083 $subtitle-font-family;

/* FORMS */
$button-font 							: 500 1.2em/1.25 $subtitle-font-family;

// RHS login block on ESPEN Collect registration webform
body.page-node-32072 {
  #sidebar-second {
    background: #F7F7F8;
    #block-user-login {
      padding: 20px;
    }
  }
}

#block-block-4 {
  padding: 20px;
  background: #F7F7F8;
  max-width: 720px;
  a.pi-login {
    background-color: #008DC9;
    border: none;
    border-radius: 0;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font: 500 1.2em/1.25 "DIN Next W01";
    padding: 0.5rem 1rem 0.25rem;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    margin-bottom: 20px;
    &:hover {
      background-color: #1C5F89;
      color: #fff;
    }
  }
}

.highcharts-tooltip {
  font-family: $subtitle-font-family;
  border: none !important;
  span.tooltip-title {
    display: block;
    font-size: 18px;
    line-height: 0.9583;
    text-transform: uppercase;
  }
  .highcharts-tooltip-box {
    stroke: transparent !important;
  }
}
.view-sections {
  margin-bottom: $vert_space*2;
}

.view {
  .view-header {
    padding: $vert_space 0;
    clear: both;
  }

  table.views-table {
    display: table;
    width: 100%;
    margin-bottom: 0;
  }
}

// Filters
.views-exposed-form {
  // Filter labels
  label {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .views-exposed-widgets {
    @include media(880px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .views-exposed-widget {
      select {
        border: $form-border;
        box-shadow: none;
        @include media(880px) {
          height: 42px;
        }
      }

      float: none;
      padding: 0.5em 0 0;
      @include media(880px) {
        flex: 0 30%;
      }

      // Override date fields
      &.views-widget-filter-field_date_value, &.views-widget-filter-field_date_value_1 {
        .form-item {
          display: block;
          margin-right: 0;

          .date-padding {
            float: none;

            .form-item {
              float: none;

              input {
                width: 100%;
                margin-right: 0;
                border: 1px solid $japanese_indigo;
                box-shadow: none;
                border-radius: 21px;
              }

              .description {
                display: none;
              }
            }
          }
        }
      }

      // Reset button
      &.views-reset-button {
        input {
          width: 100%;
          font: $button-font;
          background-color: $japanese_indigo;
          color: white;
          border-radius: 30px;
          height: 48px;
          text-transform: uppercase;

          &:hover {
            background-color: $strong_orange;
            color: $white;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    &.clearfix::after {
      content: none;
      display: none;
      clear: none;
    }
  }
}

// Sidebar filters
.side-row .view .views-exposed-form {
  .views-exposed-widget {
    flex: 100%;
    padding: 0;

    &.views-widget-filter-field_date_value {
      flex: 100%;
    }
  }
}

// Map search view
.view-search-maps, .view-resources-espen {
  .view-content {
    overflow-x: auto;
  }
}

// Datepicker filter
.ui-datepicker {
  width: 13.557rem;

  .ui-datepicker-title {
    select {
      font-size: 0.75rem;
      padding: 2px 2px 2px 10px;
      background: url($arrow-strong-blue-down) no-repeat 90% 52% / 16px 8px white;

      &.ui-datepicker-month {
        padding: 2px 2px 2px 20px;
      }
    }
  }
}

// Cartography database filters
#block-espen-cartographies-espen-cartographies {
  .views-exposed-widget {
    flex: 0 20%;
  }
}

// Dashboards
.path-dashboards {
  .tab-content {
    .content {
      padding: 20px 20px 0;
    }
  }
}

// Data dictionaries
.view-disease-dictionaries {
  .view-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .card-data-dictionary {
    padding: 5px;
  }
}

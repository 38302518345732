.dataTables_wrapper {
  .dataTables_info {
    width: 100%;
    margin-bottom: 20px;
    @include media(620px) {
      width: auto;
      float: left;
      margin-top: 10px;
    }
  }
  .dataTables_length {
    width: auto;
    float: right;
    label {
      display: flex;
      color: $strong_blue;
      text-transform: uppercase;
      font: 500 1.2rem $subtitle-font-family;
      margin-top: 10px;
      
      select {
        color: $japanese_indigo;
        min-width: 90px;
        line-height: normal;
        margin-left: 0.5rem;
        margin-top: -8px;
      }
    }
  }
  .dt-buttons {
    float: left;
    @include media(620px) {
      width: auto;
      float: right;
      margin-right: 40px;
    }
  }
  .dataTable {
    @media screen and (min-width: $narrow) {
      table-layout: fixed;
    }
    thead {
      th {
        &.sorting,
        &.sorting_asc,
        &.sorting_desc {
          color: $faded_blue;
          cursor: pointer;
        }
        &.sorting_asc {
          &:after {
            content: "";
            display: inline-block;
            height: 13px;
            width: 13px;
            margin-left: 5px;
            background-image:url("/misc/arrow-asc.png");
          }
        }
        &.sorting_desc {
          &:after {
            content: "";
            display: inline-block;
            height: 13px;
            width: 13px;
            margin-left: 5px;
            background-image:url("/misc/arrow-desc.png");
          }
        }
      }
    }
    tfoot {
      th {
        background-color: $table-header-bg-color;
        border-bottom: 0px;
        border: 1px solid rgba(44, 63, 77, 0.6);
        padding: 0.2rem 1rem;
        color:$japanese_indigo;
        font-size: 1.1rem;
        font-weight: 400;
        border-right: none;
        &:last-child {
          border-right: 1px solid rgba(44, 63, 77, 0.6);
        }
      }
    }
    a.jap-link-download {
      display: block;
      float: left;
      width: 63px;
      height: 49px;
      margin: 0 10px 0 0;
      padding: 0;
      overflow: hidden;
      text-indent: -9999px;
      background: url($ico-download-map) no-repeat 50% 50%;
      &:hover {
        background: url($ico-download-map-hover) no-repeat 50% 50%;
      }
    }
  }
  .bottom {
    display: flex;
    .dataTables_paginate.paging_simple_numbers {
      margin: 0 auto;
      a {
        &.paginate_button {
          cursor: pointer;
        }
        &.paginate_button.previous {
    			background: $strong-blue url($arrow-white-left) no-repeat scroll 50% 50%;
    			text-indent: -9999px;
    	    display: inline-block;
    	    overflow: hidden;
    	    height: 20px;
    	    width: 20px;
    			padding: 18px;
    			border-radius: 50%;
    			margin-bottom: -10px;
          &:hover {
    				background-color: $mikado_yellow;
    			}
      	}
        &.paginate_button.next {
    			background: $strong-blue url($arrow-white-right) no-repeat scroll 50% 50%;
    			text-indent: -9999px;
    	    display: inline-block;
    	    overflow: hidden;
    	    height: 20px;
    	    width: 20px;
    			padding: 18px;
    			border-radius: 50%;
    			margin-bottom: -10px;
          &:hover {
    				background-color: $mikado_yellow;
    			}
      	}
        &.disabled {
          display: none !important;
        }
      }
      & > span {
        padding: 0 1rem;
        font: 1.111rem/1.45 $subtitle-regular-font-family;
      	position: relative;
      	margin: 10px 0;
      	@media screen and (max-width: $narrow) {
      		font-size: 0.750em;
      	}
      	a {
      		color: $strong_blue;
          padding: 0.5em;
      		&:hover {
      			color:$primary;
      		}
          &.current {
            color: $primary;
            font-weight: bold;
          }
      	}
      }
    }
  }
}

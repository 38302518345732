fieldset {
	border: $secondary-border;
	margin: 0 0 $vert-space/2;
	padding: $vert-space $horz-space;
}

input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
}

label {
	font-weight: 600;
	margin-bottom: 17px;

	&.required::after {
		content: "*";
	}

	abbr {
		display: none;
	}
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
	background-color: white;
	border: $form-border; //1px solid white;
	border-radius: $form-border-radius;
	//box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: $vert-space/2;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	width: 100%;
	height:42px;

	&:hover {
		border-color: darken($form-border-color, 2%);
	}

	&:focus {
		border-color: $action-default;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}

textarea {
	resize: vertical;
}

input[type="search"] {
	@include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin-right: $vert-space/4;
}

input[type="radio"] {
	& + label {
		background: url($radio-default) no-repeat scroll 0 0 / 16px 16px transparent;
		line-height: 120%;
		font-weight: 600;
		padding: 0 0 0 24px;
	}
	&:checked + label {
		background: url($radio-active) no-repeat scroll 0 0 / 16px 16px transparent;
	}
	&:hover + label {
		background: url($radio-hover) no-repeat scroll 0 0 / 16px 16px transparent;
		color: $mikado_yellow;
	}
	&:checked:hover + label {
		background: url($radio-active) no-repeat scroll 0 0 / 16px 16px transparent;
		color: $mikado_yellow;
	}
	margin-bottom: 8px;
	display: none;
}

input[type="file"] {
	padding-bottom: $vert-space/2;
	width: 100%;
}

select {
	background: url($arrow-filter-down) no-repeat 100% 50% / auto 40px;
	width: 100%;
	border: $form-border;
	-webkit-border-radius:30px;
	-webkit-appearance:none;
	//box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: $base-font-size;
	margin-bottom: $vert-space/2;
	max-width: 100%;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	height:40px;
	&:hover {
		cursor: pointer;
		background: url($arrow-filter-down-yellow) no-repeat 100% 50% / auto 40px;
	}
	&:hover, &:active, &:focus {
		outline: none;
	}
}

#mc_embed_signup {
	position: relative;

	@include media(880px) {
		max-width: calc(50% - 20px);
	}

	@include media ($normal) {
		max-width: 380px;
	}


  input[type="email"].email {
  	color:$primary;
    padding: 12.5px 138px 12.5px $horz-space*0.75;
    display:block;
    width:100%;
		height: 44px;
    // @include media($narrow) {
    //   width:calc(100% - 128px);
    // }
  }
  input[type="submit"].button {
    display:block;
    width:100%;
		bottom: 0;
		position: absolute;
		right: 0;
    border-radius: 40px;
    height: 44px;
    width:128px;
		text-transform: uppercase;
    padding: 0.5rem 0 0.25rem;

    &:hover {
			background-color: $mikado_yellow;
			color: $primary;
    }
  }
}

.views-exposed-widget.views-submit-button .form-submit {
	border-radius:40px;
	text-transform: uppercase;
	height:48px;
	width:100%;
}

#admin-menu-search input[type="text"] {
	height: initial;
}

input[type=checkbox] {
	position:absolute;
	z-index:-1000;
	left:-1000px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height:1px;
	width:1px;
	margin:-1px;
	padding:0;
	border:0;
	& + label {
		background: url($checkbox-default) no-repeat scroll 0 0 / 20px 20px transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&:hover {
			background: url($checkbox-hover) no-repeat scroll 0 0 / 20px 20px transparent;
			// font-weight: 600;
		}
		color: $stormcloud;
		display:inline-block;
		line-height:1.1rem;
		background-repeat:no-repeat;
		background-position: 0 1px;
		// font-size:1.5rem;
		font-weight: 300;
		vertical-align:middle;
		cursor:pointer;
		padding: 0 0 0 33px;
	}
	&:checked + label {
		background: url($checkbox-active) no-repeat scroll 0 1px / 20px 20px transparent;
		font-weight: 600;
	}
}

// ESPEN Collect webform
body.node-type-form {
	article.node-form form {
		background: $antiflash_white;
		padding: 20px 40px;
		// Progress bar
		.webform-progressbar {
	    margin: 40px auto;
			.webform-progressbar-outer {
				border-color: $pearl_aqua;
				.webform-progressbar-page {
					border-color: $strong_orange;
					&.current {
						background-color: $strong_orange;
					}
					&.completed {
						background-color: $strong_orange;
					}
				}
				.webform-progressbar-inner {
					background-color: $strong_orange;
				}
			}
		}
		// Form elements
		input,
		select {
			max-width: 510px;
		}
		.form-textarea-wrapper {
			max-width: 50%;
		}
		.webform-component.webform-component--intro-text {
			margin-top: 80px;
			background: white;
			padding: 20px;
			font-size: 24px;
			line-height: 144%;
		}
		.webform-component.webform-component-date {
			.webform-container-inline {
				.form-item {
					display: inline-block;
					width: 10%;
				}
			}
			.webform-calendar {
				margin: 30px 0;
			}
		}
		.webform-component {
			margin: 30px 0;
		}
		.form-actions {
			input {
				margin-right: 20px;
			}
		}
		label {
			margin-bottom: 5px;
		}
		span.fieldset-legend {
			font-size: 28px;
			line-height: 34px;
		}
		fieldset {
			border: 1px solid rgba(44,63,78,0.5);
			margin: 0 0 20px;
			.fieldset-wrapper {
				.form-item:first-of-type {
					margin-top: 0;
				}
				.form-item:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		.form-textarea-wrapper textarea {
			min-height: 200px;
			border-radius: 0;
		}
	}
}
.user-login-form-wrapper {
  margin-top: 20px;
}
.filter-year {
  max-width: 150px;
}

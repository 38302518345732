.ajax-progress ,ajax-progress-throbber, .flag-throbber {
	display:none;
}
body {
	.view {
		.view-filters {
			opacity:1;
			transition:opacity 0.25s ease-in-out;
		}
		.view-content {
			opacity:1;
			transition:opacity 0.5s ease-in-out;
		}
	}
	&.ajax-processing {
		.view {
			.view-filters {
				opacity:0.5;
			}
			.view-content {
				opacity:0.5;
			}
			* {
				cursor:wait;
			}
		}
	}
}

.view.view-search-maps {
	.views-exposed-form .views-exposed-widget-submit .views-exposed-widget.views-reset-button {
		width:100%;
		float:none;
		input {
			margin-top:20px;
		}
	}
}

.views-exposed-form {
	background:$azureish_white;
	padding:30px;
	margin-bottom:20px;
	select {
		background-color: white;
	}
}

.side-row .view .views-exposed-form .views-exposed-widget-submit { flex: 0 100%;}

@media (min-width:880px) {
	.view .views-exposed-form .views-exposed-widget-submit {
		flex:0 100%;
		margin-left: auto;
		display: flex;
		justify-content: flex-end;
		.views-exposed-widget {
			&.views-submit-button,
			&.views-reset-button {
				flex: 0 15%;
			}
			&.views-submit-button {
				margin-right:20px;
			}
			.form-submit {
				margin-top:20px;
			}
		}
	}
}

.form-item-keywords {
  input {
    height: 48px;
    padding-left: $horz-space*2.5;
    background: white url($search) no-repeat $horz-space 50% / 20px 20px;
  }
}

.ui-datepicker th {
  padding: 2px 10px 2px 5px;
  font-weight: 500;
  font-size: 0.8rem;
}

.page-search-node {
	section#content {
		background: none;
		.search-advanced {
			margin-top: $vert-space;
		}
		.search-form {
			background: $azureish_white;
			padding: 30px;
			overflow: hidden;
			label {
				font-size: 0.9rem;
				line-height: 1.1666;
			}
			input {
				&[type='text'] {
					border-radius: 0;
					border: none;
					margin-top: 16px;
					@include media($narrow) {
						width: 33%;
					}
				}
				&[type='submit'] {
					float: right;
					padding: 15px 30px 10px;
				  border-radius: 40px;
				}
			}
			fieldset.search-advanced {
				clear: both;
			}
		}
		.search-results {
			display: flex;
			flex-wrap: wrap;
			.search-result {
				padding: $vert-space $horz-space;
				background: $search-card-bg-color;
				width: calc(50% - 20px);
				margin-right: 20px;
			}
			.card-type {
				display: inline-block;
				margin-right: 10px;
				border: 1px solid $faded_blue;
				padding: 5px 10px;
			}
		}
	}
}

#block-views-resources-espen-recent,
#block-views-res-most-pop-popular {
  @media (min-width:$narrow) {
    @include span-columns(6);
    &:nth-child(2n) { margin-right:0; }
    &:nth-child(2n+1) { clear:left; }
  }
}

body {
  &.page-taxonomy-term,
  &.page-tools-resources-documents-search {
    main {
      h1#page-title2 {
        width: 100%;
      }
      @media (min-width: 45em) {
        aside#sidebar-first {
          width: calc(100%/12*3 - 30px/2);
          margin-right: 0;
        }
        section#content {
          margin-left:0;
          width: calc(100%/12*9 - 30px/2);
        }
      }
      @media (max-width: $narrow) {
        section#content {
          padding: 0;
        }
      }
      .lhs-tabs {
        display: none;
      }
      @media (min-width: $narrow) {
        aside#sidebar-first {
          margin-right: 0 !important;
          margin-top: 10px !important;
        }
        section#content {
          margin-left: 0 !important;
          width: calc(100%/12*9) !important;
          background: $antiflash_white;
          padding: 30px;
          .resource-card {
            background-color: $white;
          }
          .view-resource-search-by-term,
          .view-resources-espen {
            @media (min-width: 720px) {
              .views-row {
                width: calc(50% - 10px);
                float: left;
                &.views-row-odd {
                  margin-right: 20px;
                }
              }
            }
          }
          .views-exposed-form {
            padding: 0;
            background: none;
          }
        }
        .lhs-tabs {
          display: block;
        }
        .block.block-espen-resource-type {
          display: none;
        }
      }
    }
    &.page-tools-resources-documents-search {
      aside#sidebar-first {
        .lhs-tabs {
          .view.view-display-id-all_menu {
            .card-name {
              a {
                background: $antiflash_white;
                color: $strong_orange;
                margin-left: 0;
                border-left: 4px solid $strong_orange;
              }
            }
          }
        }
      }
    }
  }
  &.path-tools-resources-documents {
  	main {
  		background: $azureish_white;
  		article h1 {
  			display: none;
  		}
  		// #block-block-3 {
  		// 	label[for="edit-keywords"] {
  		// 		font: 1.4rem $base-font-family;
      //     &:after {
      // 			content:'';
      // 			display:block;
      // 			width:60px;
      // 			height:2px;
      // 			background-color:$strong_orange;
      // 			margin-top:0.5rem;
      // 		}
  		// 	}
  		// }
  	}
  }
}

aside#sidebar-first {
  .chosen-container.chosen-container-single .chosen-single div b {
    background-color: $strong_orange !important;
    background-position: center 12px !important;
  }
  .chosen-container .chosen-drop {
    border: 1pc solid #fcf0ea;
  }
  a.chosen-single {
    border: 1px solid #fcf0ea;
    background: rgba(216,100,34,0.1);
    span {
      margin-top: 8px;
      font: 1.4rem/1.1785 $subtitle-font-family;
      text-transform: uppercase;
      color: $strong_orange;
    }
  }
  .lhs-tabs {
    .content,
    .card-name {
      h3,
      a {
        display: none;
        text-transform: uppercase;
        color: $black_coral;
        font: 1.4rem/1.2142 $subtitle-font-family;
        padding: 1rem 1rem 0.5rem;
        background: rgba(216, 100, 34, 0.1);
        margin: 0 0 0.3rem 0.5rem;
        border-radius: 4px 0 0 4px;
        &.country-tab-active,
        &.active {
          background: $antiflash_white;
          color: $strong_orange;
          margin-left: 0;
          border-left: 4px solid $strong_orange;
        }
        &:hover {
          background: rgba(216, 100, 34, 0.4);
          color: $japanese_indigo;
        }
        @include media($normal) {
          display: block;
        }
      }
    }
  }
}

#block-espen-resource-type-espen-resource-type-boxes {
  .view-content {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    
    .resource_type_card {
      display: block;
      text-align: center;
      color: $primary;
      margin: $vert-space/2 $horz-space/2;
      border: 1px solid $strong_blue;
      box-sizing: border-box;
      border-radius: 10px;
      position: relative;
      
      padding: $vert-space/2 $horz-space*0.1;
      height: 160px;
      width: 160px;
      
      @media (min-width:760px) {
        padding: $vert-space $horz-space*0.75;
        height: 220px;
        width: 220px;
      }
      
      &,
      &.resource_type_all {
        background: white url($ico-resources-all) no-repeat 50% 30px;
        background-size: 50px 35px;
        
        @media (min-width:760px) {
          background-size: 90px 60px;
        }
      }
      
      &:hover {
        color: white;
        background-color: $mikado_yellow;
        background-image: url($ico-resources-all-hover);
        border: 1px solid $mikado_yellow;
      }
      
      &.resource_type_824 { 
        background-image: url($ico-resources-training);
        &:hover { background-image: url($ico-resources-training-hover); }
      }
      
      &.resource_type_834 { 
        background-image: url($ico-resources-advocacy);
        &:hover { background-image: url($ico-resources-advocacy-hover); }
      }
      
      &.resource_type_826 { 
        background-image: url($ico-resources-country-master);
        &:hover { background-image: url($ico-resources-country-master-hover); }
      }
      
      &.resource_type_827 { 
        background-image: url($ico-resources-country-work);
        &:hover { background-image: url($ico-resources-country-work-hover); }
      }
      
      &.resource_type_828 { 
        background-image: url($ico-resources-meetings);
        &:hover { background-image: url($ico-resources-meetings-hover); }
      }
      
      &.resource_type_829 { 
        background-image: url($ico-resources-useful);
        &:hover { background-image: url($ico-resources-useful-hover); }
      }
      
      &.resource_type_830 { 
        background-image: url($ico-resources-espen-who);
        &:hover { background-image: url($ico-resources-espen-who-hover); }
      }
      
      &.resource_type_831 { 
        background-image: url($ico-resources-others);
        &:hover { background-image: url($ico-resources-others-hover); }
      }
      
      .title {
        text-transform: uppercase;
        display: block;
        clear: both;
        margin-bottom: $vert-space/2;
        padding-top: 70px;
        line-height: 1;
        font-family: $subtitle-font-family;        
        font-size: 1rem;
        
        @media (min-width:760px) {
          padding-top: 90px;
          font-size: 1.2rem;
        }
      }
      
      .number_resources {
        font-size: 0.8rem;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
        
        @media (min-width:760px) {
          bottom: 20px;
        }
      }
    }
  }
}

.search-wrapper #views-exposed-form-resources-espen-all {
  .views-exposed-form {
    padding: 0;
    
    .views-exposed-widgets {
      max-width: 500px;
      width: 100%;
      position: relative;
      
      .views-exposed-widget {
        padding-right: 0;
        
        &.views-widget-filter-keys {
          width: 100%;
        }
        
        &.views-submit-button {
          bottom: 10px;
          width: 81px;
          position: absolute;
          right: 0;
          
          .form-submit:hover {
            background-color: $mikado_yellow;
            color: $primary; 
          }
          
          @media (min-width: 0px) and (max-width: 580px) {
            display: none;
          }
        }
      }
    }
  }
}

// Tools & Resources landing page
.path-tools-resources {
  main {
    width: 100%;
  }
  section.postscript-first {
    background: $azureish_white;
    max-width: 100%;
    #block-espen-resource-type-espen-resource-type-boxes {
      max-width: 1600px;
      margin-left: auto;
      margin-right: auto;
      padding: 30px 0;
      h2 {
        display: flex;
        align-items: center;
        flex-flow: column wrap;
      }
    }
  }
}

.node-type-country,
.node-type-region {
  .view-grouping {
    margin-bottom: 10px;

    .view-grouping-header {
      margin-bottom: 1px;
      cursor: pointer;

      h2 {
        font-family: $subtitle-font-family;
        text-transform: uppercase;
        position: relative;
        background-color: $white;
        font-size: 1.3rem;
        padding: 1rem;
        color: $faded_blue;
        margin: 0;
        transition: 250ms ease all;
        justify-content: flex-start;
        border-radius: 4px;

        &:before, &:after {
          background-color: $strong_blue;
          content: '';
          display: block;
          width: 24px;
          height: 4px;
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%) rotate(0deg);
          transition: 1s ease all;
        }

        &:after {
          transform: translateY(-50%) rotate(90deg);
          margin-top: 0;
        }

        &:hover {
          background-color: $mikado_yellow;
        }
      }
    }

    .view-grouping-content {
      display: none;
      background-color: $white;
      padding: 1.5rem;

      & > h3 {
        display: none;
      }

      .view-content {
        overflow-x: auto;
      }

      .view-data-source {
        text-align: center;

        .data-download {
          text-transform: uppercase;
          font-weight: 700;
          display: inline-block;
          margin: 1rem 0 0;
        }
      }

      .view-data-source-legend {
        text-align: center;

        .data-legend-download {
          font-size: 0.8rem;
          display: inline-block;
          margin: 0;
        }
      }
    }

    &.expanded {
      .view-grouping-header {
        h2 {
          background-color: $azureish_white;
          color: $faded_blue;

          &:before, &:after {
            background-color: $strong_blue;
            transform: translateY(-50%) rotate(720deg);
          }
        }
      }
    }
  }

  #sidebar-second {
    .block {
      margin-bottom: $vert-space;

      &:not(#block-block-1):not(#block-remora-highmaps-data-highmaps-data) {
        background-color: $white;
        padding: 1.5rem;
      }

      .view-location {
        .views-row {
          div {
            margin-bottom: $vert_space;

            h3 {
              margin-bottom: 0;
              line-height: 1.125;
            }

            span {
              display: block;
              font-size: 0.8rem;
              font-style: italic;
            }
          }
        }
      }

      &#block-views-country-resources-block,
      &#block-views-country-resources-region-res {
        .view-header {
          padding: 0;

          h2 {
            font: 1.3rem/1.1923 $subtitle-font-family;
            justify-content: flex-start;
            text-transform: uppercase;
          }
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            margin-bottom: $vert-space/2;
            padding: 0;

            img.file-icon {
              //display:none;
            }

            a {
              word-wrap: break-word;
            }
          }
        }
      }
    }
  }
}

.page-map-generation,
.node-type-country,
.node-type-region {
  .content-hero {
    padding-top: #{$gutt};

    .view-display-id-title {
      .views-row {
        display: flex;
        margin: 0 calc(#{$gutt} / 2);

        h1 {
          margin: 0;

          &:after {
            display: none;
          }
        }

        .card-country-flag {
          margin-right: 20px;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
      }
    }
  }

  .tab-content {
    &.intro-overview-content {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    &.intro-content {
      background: $ucla_blue;
      padding: $horz-space $vert-space;

      .block-espen-country-endemicity-map {
        clear: both;
      }
    }

    &.maps-content {
      .view-maps-total,
      .block-espen-country-endemicity-map {
        margin-bottom: $vert-space;
      }
    }
  }

  button.download.download-partner-data,
  button.download.download-coendemicity-data,
  button.download.download-drug-regimen-data,
  button.download.download-wash-data {
    display: flex;
    align-items: center;
    text-indent: 0;
    background-color: transparent;
    width: auto;
    height: auto;
    color: white;
    margin-top: 20px;
    text-transform: uppercase;
    font-size: 1.2rem;

    &:before {
      content: "";
      display: inline-block;
      background: white url($ico-download-blue) no-repeat 50% 50% / 20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 6px;
    }

    &:hover {
      background-color: transparent;
      color: $mikado_yellow;

      &:before {
        background: $mikado_yellow url($ico-download-indigo) no-repeat 50% 50% / 20px;
      }
    }
  }

  article > h1 {
    display: none;
  }

  #content {
    .content-main {
      display: flex;
      flex-wrap: wrap;
    }
  }

  #block-espen-country-blocks-espen-country-node-tabs,
  #block-espen-region-blocks-espen-region-node-tabs {
    background: white;
    width: 100%;
    @media (max-width: $narrow) {
      margin-bottom: 20px;
    }

    h2 {
      display: none;
    }
  }

  #block-espen-country-blocks-espen-country-node-overview-tabs {
    width: 100%;
    padding: 20px;
    @media (min-width: $normal) {
      padding: 30px 30px 0;
    }

    h2.title-overview:after {
      display: none;
    }
  }


  #block-views-location-overview-block {
    margin-bottom: 20px;
    padding: 0 20px 5px;
    background: white;

    .views-row {
      #all-content {
        display: none;
      }

      span.more-link {
        width: 100%;
        display: block;

        text-align: left;
        margin-top: 20px;

        a {
          text-transform: uppercase;
          font: 500 1.2rem/1.1666 $subtitle-font-family;

          &:after {
            content: "";
            display: inline-block;
            width: 30px;
            height: 30px;
            background: $strong_blue url($ico-expand-white) no-repeat 50% 50%;
            border-radius: 50%;
            margin-left: 5px;
            vertical-align: sub;
          }

          &:hover {
            &:after {
              background-color: $faded_blue;
            }
          }
        }
      }
    }
  }

  #all-content {
    background: white;
    padding: 20px;
  }

  #cboxContent {
    border-radius: 0;

    button.cbox-close-plain {
      color: white;
    }
  }


  .wash-map-wrapper {
    width: 100%;
    position: relative;
    @media (min-width: 720px) {
      width: calc(50% - 10px);
    }
    &.trachoma {
      width: 100%;
    }
  }

  #block-espen-country-drug-packages-coendemicity-treatment-map,
  #block-espen-country-coendemicity-map-espen-country-coendemicity-map {
    .content {
      display: flex;
      flex-direction: column;
    }
  }

  #block-espen-country-drug-packages-coendemicity-treatment-map,
  #block-espen-country-coendemicity-map-espen-country-coendemicity-map,
  #block-espen-wash-maps-espen-wash-sth-sch-map,
  #block-espen-wash-maps-espen-wash-trachoma-map {
    width: calc(100% - 20px);
    margin: 0px 20px 20px 0px;
  }

  #block-espen-wash-maps-espen-wash-sth-sch-map,
  #block-espen-wash-maps-espen-wash-trachoma-map {
    h4 {
      color: white;
      margin-bottom: 20px;
      max-width: 80%;
    }

    .views-exposed-form {
      background: transparent;
      padding: 0;
      margin-bottom: 6px;;
    }

    .views-exposed-widget {
      align-items: baseline;
      display: flex;
      flex-wrap: wrap;
      padding: 0;

      label {
        color: white;
        font-size: 16px;
        margin-right: 16px;
        margin-bottom: 10px;
      }
    }

    .wash-map-wrapper {
      background: $ucla_blue;
      padding: 20px;
    }
  }

  #block-espen-country-drug-packages-coendemicity-treatment-map,
  #block-espen-country-coendemicity-map-espen-country-coendemicity-map,
  #block-espen-country-endemicity-map-espen-country-endemicity-map {
    background: $ucla_blue;
    padding: 20px;

    h4 {
      width: 100%;
      font: 1.4rem/1.2142 $title-font-family;
      margin-bottom: 12px;
      color: white;
    }

    .views-exposed-form {
      background: transparent;
      padding: 0;
      margin-bottom: 0;

      .views-exposed-widget {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        padding: 0;

        .form-item {
          width: 100%;
          @media (min-width: $normal) {
            width: auto;
            margin-right: 60px;
          }

          label {
            color: white;
            font-size: 1rem;
          }

          input[type="checkbox"] {
            & + label {
              background: url($checkbox-default) no-repeat scroll 0 0 / 22px 22px transparent;
              line-height: 120%;
              font-weight: 600;
              padding: 0 0 0 34px;
            }

            &:checked + label {
              background: url($checkbox-active-alt) no-repeat scroll 0 1px / 22px 22px transparent;
            }

            &:hover + label {
              background: url($checkbox-hover) no-repeat scroll 0 1px / 22px 22px transparent;
              color: $mikado_yellow;
            }

            &:checked:hover + label {
              background: url($checkbox-active-alt-yellow) no-repeat scroll 0 1px / 22px 22px transparent;
              color: $mikado_yellow;
            }
          }
        }
      }
    }

    select {
      padding-right: 50px;
    }
  }

  #block-views-country-resources-block {
    margin-bottom: 20px;
    padding: 20px;
    background: white;

    .view-id-country_resources.view-display-id-block {
      .view-header {
        padding: 0;

        h2 {
          text-transform: uppercase;
          font: 1.3rem/1.1923 $subtitle-font-family;
          margin-bottom: 12px;

          &:after {
            display: none;
          }
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;

      }
    }
  }

  #block-map-generation,
  #block-geojson-generation,
  #block-espen-region-blocks-espen-region-node-content {
    margin: 30px;
    width: 100%;

    .country-content:not(:first-of-type) {
      display: none;
    }
  }

  #block-espen-country-blocks-espen-country-node-content {
    width: 100%;
    margin: 30px;
    overflow: hidden;

    #disease-title h2:after {
      display: none;
    }

    .country-content:not(:first-of-type) {
      display: none;

      .tab-content {
        &.maps-total,
        &.maps-content {
          display: none;
        }
      }
    }
  }

  #block-espen-country-partner-matrix-espen-country-partner-matrix {
    .view-partner-matrix {
      .views-exposed-form {
        background-color: $ucla_blue;
        margin-bottom: 0;

        label {
          color: white;
        }

        select {
          background: url($arrow-filter-down-yellow) no-repeat 100% 50% / auto 40px;

          &:hover {
            background: url($arrow-filter-down-faded-blue) no-repeat 100% 50% / auto 40px;
          }
        }
      }
    }

    #container-matrix {
      background-color: $ucla_blue;
      padding: 20px 20px 80px 20px;
    }

    button.download {
      margin-top: -60px;
      margin-left: 20px;
    }
  }

  #block-espen-jap-access-espen-jap-access-country,
  #block-espen-country-partner-matrix-espen-country-partner-matrix,
  #block-espen-cartographies-espen-cartographies-country {
    width: 100%;
  }

  #block-espen-data-download-espen-data-download {
    .views-exposed-widget:first-child {
      display: none;
    }
  }
}

body.node-type-region {
  .country-content-overview {
    display: flex;
    flex-direction: column;
    @media (min-width: $narrow) {
      flex-direction: row;
      .region-main {
        width: calc(75% - 20px);
        margin-right: 20px;
      }
      .region-rhs {
        width: 25%;
      }
    }
  }

  #block-system-main {
    display: none;
  }

  #block-espen-region-blocks-espen-region-node-map,
  #block-views-jump-country-region-cx {
    width: 320px;
  }

  #block-espen-region-blocks-espen-region-node-map {
    margin-top: 30px;
    margin-right: 30px;
    margin-left: auto;
  }

  #block-views-jump-country-region-cx {
    margin-right: 30px;
    margin-left: auto;
  }

  .attachment-link {
    background: white;
  }
}

body.page-map-generation,
body.node-type-country,
body.node-type-region {
  .block.block-espen-pc-coverage-chart {
    margin-top: 20px;
  }

  #content {
    .content-main {
      background: $antiflash_white;
    }
  }

  .lhs-tabs {
    h3.country-tab {
      display: none;
      cursor: pointer;
      @include media($narrow) {
        display: block;
      }
    }

    select:not([name^="container-map-"]):not(.table-filter) {
      @include media($normal) {
        display: none;
      }
    }

    .content {
      .tabs-wrapper {
        display: flex;

        h3.country-tab {
          font-family: $subtitle-font-family;
          height: 80px;
          width: 100px;
          font-size: 20px;
          background: rgba(216, 100, 34, 0.2);
          text-align: center;
          text-transform: uppercase;
          padding: 10px 10px 5px;
          margin-right: 2px;
          margin-bottom: 0;
          border-radius: 4px 4px 0px 0px;

          &.country-tab-active {
            background: #E9F1F7;
          }

          &:hover {
            background: #D86422;
            color: white;
          }

          &:before {
            content: "";
            display: block;
            height: 43px;
            width: 80px;
          }

          // Overview
          &.country-tab-overview {
            &:before {
              background: url($overview-ico-orange) no-repeat scroll 50% 50% / auto 37px;
            }

            &.country-tab-active {
              &:before {
                background: url($overview-ico-grey) no-repeat scroll 50% 50% / auto 37px;
              }
            }

            &:hover {
              &:before {
                background: url($overview-ico-white) no-repeat scroll 50% 50% / auto 37px;
              }
            }
          }

          // LF
          &.country-tab-lf {
            &:before {
              background: url($lf-ico-orange) no-repeat scroll 50% 50% / auto 37px;
            }

            &.country-tab-active {
              &:before {
                background: url($lf-ico-grey) no-repeat scroll 50% 50% / auto 37px;
              }
            }

            &:hover {
              &:before {
                background: url($lf-ico-white) no-repeat scroll 50% 50% / auto 37px;
              }
            }
          }

          // ONCHO
          &.country-tab-oncho {
            &:before {
              background: url($oncho-ico-orange) no-repeat scroll 50% 50% / auto 43px;
            }

            &.country-tab-active {
              &:before {
                background: url($oncho-ico-grey) no-repeat scroll 50% 50% / auto 43px;
              }
            }

            &:hover {
              &:before {
                background: url($oncho-ico-white) no-repeat scroll 50% 50% / auto 43px;
              }
            }
          }

          // LOA
          &.country-tab-loa {
            &:before {
              background: url($loa-ico-orange) no-repeat scroll 50% 50% / auto 24px;
            }

            &.country-tab-active {
              &:before {
                background: url($loa-ico-grey) no-repeat scroll 50% 50% / auto 24px;
              }
            }

            &:hover {
              &:before {
                background: url($loa-ico-white) no-repeat scroll 50% 50% / auto 24px;
              }
            }
          }

          // SCH
          &.country-tab-sch {
            &:before {
              background: url($sch-ico-orange) no-repeat scroll 50% 50% / auto 27px;
            }

            &.country-tab-active {
              &:before {
                background: url($sch-ico-grey) no-repeat scroll 50% 50% / auto 27px;
              }
            }

            &:hover {
              &:before {
                background: url($sch-ico-white) no-repeat scroll 50% 50% / auto 27px;
              }
            }
          }

          // STH
          &.country-tab-sth {
            &:before {
              background: url($sth-ico-orange) no-repeat scroll 50% 50% / auto 29px;
            }

            &.country-tab-active {
              &:before {
                background: url($sth-ico-grey) no-repeat scroll 50% 50% / auto 29px;
              }
            }

            &:hover {
              &:before {
                background: url($sth-ico-white) no-repeat scroll 50% 50% / auto 29px;
              }
            }
          }

          // TRACHOMA
          &.country-tab-trachoma {
            &:before {
              background: url($trachoma-ico-orange) no-repeat scroll 50% 50% / auto 24px;
            }

            &.country-tab-active {
              &:before {
                background: url($trachoma-ico-grey) no-repeat scroll 50% 50% / auto 24px;
              }
            }

            &:hover {
              &:before {
                background: url($trachoma-ico-white) no-repeat scroll 50% 50% / auto 24px;
              }
            }
          }

          // WASH
          &.country-tab-wash {
            &:before {
              background: url($wash-ico-orange) no-repeat scroll 50% 50% / auto 24px;
            }

            &.country-tab-active {
              &:before {
                background: url($wash-ico-grey) no-repeat scroll 50% 50% / auto 24px;
              }
            }

            &:hover {
              &:before {
                background: url($wash-ico-white) no-repeat scroll 50% 50% / auto 24px;
              }
            }
          }
        }
      }
    }
  }
}

.view-map-table .view-header {
  display: none;
}

.underline::after {
  content: '';
  display: block;
  width: 60px;
  height: 2px;
  background-color: #D86422;
  margin-top: 0.25rem;
}

.block-espen-country-forecast-dashboard,
.block-espen-country-disease-dashboard {
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.dashboard-block {
  h4, h6 {
    width: 100%;
  }

  h6 {
    margin-top: 30px;
  }

  background: white;
  padding: $vert-space $horz-space;
  width: 100%;
  margin-bottom: 20px;

  &.half {
    @media (min-width: $normal) {
      width: calc(50% - 10px);
    }
  }

  &.donut,
  &.stats {
    min-height: 400px;
  }

  &.chart {
    min-height: 600px;
  }

  &.forecast-text,
  &.forecast-stats {
    min-height: 300px;
  }

  &.map {
    display: flex;
    flex-direction: column;
    min-height: 600px;
    @media (min-width: $normal) {
      flex-direction: row;
    }

    .coverage-map {
      width: 100%;
      @media (min-width: $normal) {
        width: calc(60% - 20px);
        margin-right: 20px;
      }
    }

    .iu-chart {
      width: 100%;
      max-height: 700px;
      @media (min-width: $normal) {
        width: 40%;
        margin-top: 65px;
      }

      &.empty {
        background: $antiflash_white;
        font-size: 18px;
        padding: 30px;
        display: flex;
        text-align: center;
        align-items: center;
      }

      .highcharts-xrange-series .highcharts-data-label span {
        text-align: center;
      }
    }
  }

  &.overview-main {
    padding: 30px;
  }

  select {
    border: 1px solid $slate_grey;
    color: $slate_grey;
    font-size: 16px;
    width: auto;
    padding-right: 100px;
    padding-left: 20px;
  }
}

.looking-forwards-wrapper {
  margin-top: 20px;
  border-top: 20px solid $antiflash_white;
  margin-left: -30px;
  margin-right: -30px;
  .looking-forwards {
    padding: 30px;
  }
}

#country-treatment-map,
#country-coendemicity-map {
  background: transparent;
}

.key-stat-label {
  width: 50%;
  display: inline-flex;
  flex-direction: column;
  font-size: 16px;
  margin-bottom: 10px;

  span {
    font-weight: bold;
  }
}

.key-stat-coverage-wrapper {
  font-size: 16px;

  h6 {
    margin-top: 0;
  }
}

.donut-year-wrapper {
  margin-top: 0;
  margin-bottom: 20px;

  &::after {
    content: "";
    display: block;
    height: 2px;
    width: calc(100% + 40px);
    background: $antiflash_white;
    margin-left: -20px;
  }
}

.stats-year-wrapper {
  display: inline-block;
}

.container-loader {
  display: none;
  align-content: center;
  justify-content: center;

  .highcharts-loading {
    height: 100%;
  }
}

.d-flex {
  display: flex !important;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.fw-normal {
  font-weight: normal !important;
}


// Country overview tab
.overview-main {
  width: 100%;
  @media (min-width: $normal) {
    width: calc(75% - 20px);
    margin-right: 20px;
  }
}

.overview-sidebar {
  width: 100%;
  @media (min-width: $normal) {
    width: 25%;
  }

  .data-source {
    background: white;
    padding: 30px;
    margin-bottom: 20px;

    h2 {
      text-transform: uppercase;
      font: 1.3rem/1.1923 $subtitle-font-family;
      margin-bottom: 12px;

      &:after {
        display: none;
      }
    }
  }
}

.stat-box-wrapper {
  .stat-row {
    margin-bottom: 40px;
    justify-content: space-between;
    flex-wrap: wrap;

    &.columns {
      .stat-box {
        margin-bottom: 20px;
        @media (min-width: $narrow) {
          margin-bottom: 0;
        }
      }
    }

    &.two-columns .stat-box {
      width: 100%;
      @media (min-width: $narrow) {
        width: calc(50% - 10px);
      }
    }

    &.three-columns .stat-box {
      width: 100%;
      margin-bottom: 20px;
      @media (min-width: $normal) {
        width: calc(50% - 10px);
        margin-bottom: 20px;
      }
      @media (min-width: $wide) {
        width: calc(33% - 10px);
      }
    }

    &.five-columns .stat-box {
      width: calc(50% - 10px);
      margin-bottom: 20px;
      @media (min-width: $normal) {
        width: calc(33% - 10px);
        margin-bottom: 20px;
      }
      @media (min-width: $wide) {
        width: calc(20% - 10px);
      }
    }
  }
}

.stat-box {
  background: $antiflash_white;

  .stat-box-title {
    background: $azureish_white;
    padding: 10px 20px;
    font-size: 22px;
    line-height: 26px;
    justify-content: center;

    .title-section {
      display: flex;
      flex-direction: column;

      &.sec-1 {
        padding-right: 30px;
        border-right: 2px solid $mikado_yellow;
      }

      &.sec-2 {
        padding-left: 30px;
      }
    }
  }

  .stat-box-content {
    padding: 20px;
    font-size: 18px;
    line-height: 140%;
  }

  &.yellow {
    .stat-box-title {
      background: $kournikova;
    }

    &.alt {
      .stat-box-title {
        flex-direction: column;

        .sec-1 {
          padding: 0 0 10px;
          border-right: 0;
          border-bottom: 2px solid $mikado_yellow;
        }

        .sec-2 {
          padding: 10px 0 0;
        }
      }
    }
  }

  &.report {
    .stat-box-title {
      padding: 30px;
      text-align: center;
    }

    .stat-box-content {
      padding: 10px 20px;

      span {
        height: 60px;
        width: 80px;
        display: inline-flex;
        align-items: flex-end;
        justify-content: center;
        margin-top: -26px;
        background: #E9F1F7;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
      }
    }
  }
}

.text-center-bold {
  text-align: center;
  font-weight: bold;
  font-size: 24px !important;
  line-height: 144% !important;
}

// carto table header
#total-results {
  align-items: center;
}

.table-header {
  justify-content: space-between;
  flex-wrap: wrap;

  label {
    display: flex;
    margin: 0 0 0 10px;
    width: 150px;
    align-items: baseline;
    color: #008DC9;
    text-transform: uppercase;
    font: 500 1.2rem $subtitle-font-family;

    select {
      margin: 0 0 0 10px;
      color: $primary;
    }
  }

  .download-show-wrapper {
    align-items: center;
  }
}

.dl-buttons {
  margin-top: 20px;

  button {
    margin-right: 20px;
  }
}

.summary-map-title-wrapper {
  display: flex;
  select {
    max-width: 150px;
    margin-left: 20px;
  }
}

#treatment-strategy-wrapper,
#coendemicity-map-wrapper {
  background: white;
  min-height: 700px;
  display: grid;
}

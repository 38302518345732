body.front {
  main {
    padding: 0;
    //margin-top: 2px;
    section#content {
      width: 100%;
      margin: 0;
      padding: 0;
      .view-sections {
        margin: 0;
        .section-inner {
          overflow: visible;
        }
      }
    }
  }
  main,
  .postscript-first {
    margin: 0;
    max-width: none;
    
    @media (max-width: $narrow) {
      h2 {
        text-align: center;
        font-size: 1.4rem;
      }
    }
    #block-espen-jump-block-home-jump-block,
    #block-views-search-buttons-buttons {
      .block-inner {
        max-width: 1600px;
        margin: 0 auto;
      }
    }
  }
  main {
    z-index: 10;
    position: relative;
    article {
      position: relative;
    }
  }
  .alert-banner {
    a.alert-link {
      background: #FFC914;
      display: block;
      width: 100%;
      padding: 20px 20px 10px;
      .alert-text-wrapper {
        max-width: 1600px;
        margin: 0 auto;
        // font-family: DIN Next LT Pro;
        font-size: 24px;
        color: #2C3F4D;
        display: flex;
        flex-wrap: wrap;
        .alert-title {
          font-weight: bold;
          line-height: 29px;
          display: flex;
          align-items: center;
          margin-right: 30px;
          position: relative;
          margin-bottom: 10px;
          /* icon in alert banner
          &:before {
            content: "";
            display: inline-block;
            background: url($covid-icon) no-repeat 50% 50%;
            height: 45px;
            width: 45px;
            border-radius: 50%;
            border: 2px solid #2C3F4D;
            margin-right: 20px;
          }
          &:after {
            content: "";
            display: block;
            background: #2C3F4D;
            height: 2px;
            width: 80px;
            position: absolute;
            left: 65px;
            bottom: 2px;
          } */
        }
        .alert-text {
          line-height: 144%;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }
      }
      &:hover {
        background: #2C3F4D;
        .alert-text-wrapper {
          color: white;
          /* alert banner icon
          .alert-title {
            &:before {
              content: "";
              background: url($covid-icon-hover) no-repeat 50% 50%;
              border: 2px solid white;
            }
            &:after {
              background: white;
            }
          }
           */
        }
      }
    }
  }
}
// Homepage hero
.view-id-homepage_hero.view-display-id-stats {
  .attachment.attachment-before {
    .views-row {
      padding-left: calc((100% - 1600px) / 2);
      padding-right: calc((100% - 1600px) / 2);
      &.bg-3CABDD {
        background-color:$picton_blue;
      }
      &.bg-2C3F4D {
        background-color:$japanese_indigo;
      }
    }
    .card-image-et {
      display: flex;
      width: 100%;
      position: relative;
      img {
        position: absolute;
        bottom: 0;
        left: 10px;
        width: calc(100%/12*5 - 50px);
        max-width: 400px;
        transition: 250ms ease all;
        display: none;
        @include media($normal) {
          display: block;
        }
        @include media(1250px) {
          left: 60px;
        }
      }
    }
  }
  .hero-content {
    float:right;
    text-align: center;
    color: $white;
    margin: 0;
    width: 100%;
    @include media($wide) {
      padding-right: calc((100% - 1600px) / 12 * 8);
      margin-right: 0;
      margin-top: 30px;
      width: calc(100%/12 * 8 - 30px);
    }
    &> .view-content {
      flex-direction: column;
      align-items: stretch;
      align-content: center;
      @include media($narrow) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
      }
      @media (min-width: 960px) and (max-width: 1035px) {
        width: calc(100%/12 * 9 - 30px);
        float: right;
      }
      @include media(1036px) {
        width: calc(100%/12 * 8 - 30px);
        float: right;
      }
      @include media($wide) {
        width: auto;
        float: none;
      }
      .views-row {
        .stat-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          transition: 250ms ease all;
          @include media($narrow) {
            flex-direction: column;
            padding: 20px;
            margin: 5px;
            width: 230px;
            height: 230px;
            background: url($dashed-circle) no-repeat scroll 50% 50% / contain;
          }
          @include media($normal) {
            width: 210px;
            height: 210px;
          }
          @media (min-width:$wide) and (max-width:1395px) {
            margin: 0 10px;
            width: 230px;
            height: 230px;
          }
          @include media(1396px) {
            margin: 0 30px;
            width: 240px;
            height: 240px;
          }
          .card-title {
            font-size: 1rem;
            line-height: 1.2142;
            background: url($dashed-circle-small) no-repeat scroll 50% 50% / contain;
            width: 110px;
            height: 110px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-weight: 700;
            @include media($narrow) {
              font-size: 1.4rem;
              background: none;
              width: auto;
              height: auto;
              display: block;
            }
            .big {
              font-size: 1.5rem;
              font-weight: 900;
              line-height: 1.2;
              @include media($narrow) {
                font-size: 3rem;
              }
            }
          }
        }
        .card-stat-content {
          margin-left: 20px;
          width: calc(100% - 150px);
          text-align: left;
          max-width: 400px;
          @include media($narrow) {
            margin: 0;
            width: auto;
            text-align: center;
            max-width: none;
          }
        }
      }
    }
    .view-header {
      padding: 30px 20px 20px;
      @include media($narrow) {
        padding: 20px 10px;
      }
      @include media($normal) {
        width: calc(100%/12 * 8 - 30px);
        float: right;
      }
      @include media($wide) {
        width: auto;
        float: none;
      }
      h1 {
        color: $white;
        font-size: 1.4rem;
        line-height: 1.4285;
        @include media($narrow) {
          font-size: 2.5rem;
          line-height: 1.44;
        }
        &:after {
          display: none;
        }
      }
    }
    .attachment.attachment-after {
      @include media($normal) {
        width: calc(100%/12 * 8 - 30px);
        float: right;
      }
      @include media($wide) {
        width: auto;
        float: none;
      }
      .card-homepage-description {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 1.1rem;
        line-height: 1.4545;
        padding: 30px 20px;
        margin-top: 20px;
        background: $white;
        border-radius: 10px;
        color: $japanese_indigo;
        margin-bottom: -60px;
        @include media($normal) {
          padding: 40px;
          font-size: 1.5rem;
          line-height: 1.4333;
          margin-top: 50px;
        }
      }
    }
  }
}

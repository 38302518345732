// /* DEFAULT CARD LAYOUT	*/
.card {
	border:$card-border;
	border-radius: $card-border-radius;
	box-shadow:$card-shadow;
	margin-bottom:$card-margin-bottom;
	
	transition: $card-transition;
	
	display: block; //?

	&.slick-slide {
		margin-top: 10px;
	}
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
	}

	h3 {
		display:block;
		font:$card-title-font;
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
		}
	}	
	.card-text {
		padding: $card-text-padding;
		a { word-wrap: break-word; }
	}
}

article.node-event {
	.card {
		.card-text {
			.card-display-date {
				padding: 0;
				.card-display-date-wrapper {
					display: inline-flex;
					border-bottom: 2px solid $mikado_yellow;
					margin-bottom: 20px;
					.card-display-date-day-wrapper {
						font-size: 1.9rem;
						line-height: 1.2105;
						margin-top: -9px;
						margin-bottom: 9px;
					}
					.card-display-date-month-year-wrapper {
						font-size: 0.7rem;
						line-height: 1.0714;
						text-transform: uppercase;
						margin-left: 6px;
					}
				}
			}
			h3 + .card-display-date {
				margin-bottom: 6px;
			}
		}
	}
}

.card-display-date {
	background: $card-date-bg-color;
	border-bottom:$card-date-border;
	border-radius:$card-date-border-radius;
	color:$card-date-text-color;
	font:$card-date-font;
	padding:12px 20px 10px;
}


// /* MORE LINK */
.section-row:not(.view-type-slider) //, .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
	{
	.card-more-link {
		display:table !important;
		margin-left:2.35765%;
		text-align:center;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:16px;
				margin:0 0 -2px 10px;
				width:14px;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}

// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	overflow: hidden;
	a {
		&:after {
			content: "";
			display: inline-block;
			width: 30px;
			height: 30px;
			//border-radius: 50%;
			margin-left: 10px;
			vertical-align: sub;
		}
	}
}



// /* INNER CARD ELEMENTS */
.tweet .card-text {
	display:block;
	font: 1rem/1.45 $base-font-family;
}

.card-logo {
	background:#FFFFFF;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}

.card-tags {
	display: flex;
	flex-flow: wrap;
	font: 0.8rem/1.1875 $base-font-family;
	color: $faded_blue;
	ul {
		display: flex;
	}
	ul li,
	.card-tag {
		margin-right: $horz-space/2;
		border: 1px solid $faded_blue;
		padding: $vert-space/4 $horz-space/2;		
	}
}

.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.9em;
		line-height:1.2105;
	}
	.card-date-month {
		font-size:0.7rem;
		line-height:1.0714;
		text-transform:uppercase;
	}
}

.card-citation {
	font-style:italic;
}
.card-date,
.card-location {
	font-size:0.8rem;
	line-height:1.1875;
}
.card-type {
	font-size:0.8rem;
	line-height:1.1875;
}
// .card-label {
// 	font-weight:bold;
// }

.card-date-display-override {
	font-size: 0.8rem;
}
.card-resource-type,
.card-content-type {
	display:inline-block;
	font-size: 0.8rem;
	line-height:1.1875;
}
.card-journal {
	display: inline-block;
	font-weight: 400;
	font-size: 0.8rem;
	&:after {
		content:"|";
		margin: 0 0px 0 2px;	
	}
}
// .card-email {
// 	font-weight: 600;
// }

.card-job-title {
	font-size:0.8rem;
	line-height:1.1875;
}

.card-footer {
	display: block;
	line-height:1.1875;
}

@media (min-width:$narrow) and (max-width:959px) {
	.card-with-summary {
		.card-summary {
			font-size: 0.875rem;
			line-height: 1.42857142857143;
		}
	}
}
//Display box-link card-summary on hover 
//@include card-hidden-summary;

// RESOURCES CARDS
.resources-view .card {
	.card-text {
		padding-bottom: $vert-space/2;
		.file-icon {
			font-size: 0.800rem;
			text-transform: uppercase;
			margin-bottom: $vert-space/2;
			.card-extension {
				background-color: $mikado_yellow;
				display: inline-block;
				padding: 0 $horz-space/4;
				margin-right: $horz-space/2;
			}
			.card-filesize {
				display: inline-block;
			}
		}
		.file-text {
			margin-bottom: $vert-space/2;
			.card-resource-file {
				margin-bottom: $vert-space/2;
			}
			.card-dcid {
				margin-top: $vert-space/4;
				font: 0.8rem/1.1875 $base-font-family;
			}
		}
		
		.card-tags {
			ul li,
			.card-tag {			
				margin-bottom: $vert-space/2;
			}
		}
	}
}
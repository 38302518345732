table {
  border-collapse: separate;
  border-spacing: 0;
  margin: 0 0 $vert-space;
  width: auto;
  font-size: 1rem;
  display: block;
  overflow-x: auto;
  @include media($narrow) {
    display: table;
  }

  caption {
    background: $table-caption-bg-color;
    border-bottom: $table-caption-border;
    color: $table-caption-text-color;
    font: $table-caption-font;
    padding: $vert-space/3 $vert-space/2;
  }

  th {
    background: $table-header-bg-color;
    border-bottom: $table-header-border;
    font-weight: bold;
    padding: $vert-space/2 0;
    text-align: left;
    word-wrap: break-word;
  }

  td {
    border-bottom: $primary-border;
    line-height: $base-line-height;
    padding: $table-padding;
    border-left: $table-cell-border;
    border-right: $table-cell-border;
    word-wrap: break-word;
    border: $table-cell-border;
  }

  tr {
    &.even {
      td {
        background-color: $table-stripe-bg;
      }
    }

    &.odd {
      td {
        background-color: $table-bg-color;
      }
    }

    td {
      border-right: none;

      &:last-child {
        border-right: $table-cell-border;
      }
    }
  }

  thead,
  tfoot {
    th {
      background-color: $table-header-bg-color;
      border-bottom: 0px;
      border: 1px solid rgba(44, 63, 77, 0.6);
      padding: $table-padding;
      color: $japanese_indigo;
      font-size: 1.1rem;
      font-weight: 400;
      border-right: none;

      &:last-child {
        border-right: 1px solid rgba(44, 63, 77, 0.6);
      }
    }
  }

  tr,
  td,
  th {
    background-color: $white_smoke;
    vertical-align: middle;
  }

  tbody {
    background-color: $table-bg-color;

    tr:hover > td, tr:hover > th {
      background-color: $table-hover-bg-color;
    }

    tr:first-child td {
      border-top: $table-header-border;
    }

    tr:last-child td {
      border-bottom: $table-border;
    }

    td {
      border-bottom: 0px;
      border-top: $table-border;
      padding: $table-padding;

      button {
        display: inline-block;
        font-size: .7em;
        line-height: $base-line-height;
        margin-bottom: .3em;
        margin-right: 0.5em;
        outline: none;
        padding: .3em 1em;
        width: 100%;

        @include media($narrow) {
          margin-bottom: 0;
          width: auto;
        }
      }
    }
  }
}

table#container-cartographies-table,
table#container-cartographies-country-table {
  font-size: 0.8rem;
  thead th,
  tfoot th {
    font-size: 0.8rem;
    font-weight: bold;
  }
}

.espen-collect-table {
  border-top: 0px !important;
  th {
    vertical-align: middle !important;
    border-bottom: 0px !important;
    color: white;
    background-color: $strong_orange;
    border: 1px solid #B94F12;

    &:nth-child(-n+3) {
      color: #2C3F4D;
      background-color: #E0E2E5;
      border: 1px solid #C2C3C5;
    }
  }

  td{
    color: $black_coral;
    background-color: white;
    border: 1px solid #E0E2E5;

    &:nth-child(-n+3) {
      color: $black_coral;
      background-color: $white_smoke;
    }
  }
}


// SEARCH BUTTONS

.view-search-buttons .views-field {
  display: inline-block;
  width: 100%;
	@media (min-width:960px) {
		width: calc(33% - 40px);
		margin-right:60px;
	}
	&.views-field-path-2 {
  	margin-right: 0;
	}
}
a.search-button {
  display: inline-block;
  position: relative;
	width: 100%;
	//float: left;
	.title-wrapper {
		display: inline-block;
	  background: $strong_blue;
	  padding: 20px;
	  height: 229px;
	  width: 100%;
		border-radius: 10px;
		.button-title {
			position: absolute;
	    color: white;
	    text-transform: uppercase;
	    top:115px;
	    left: calc(50% - 60px);
			border-bottom:white solid 2px;
			&:before {
				display:inline-block;
				content:"";
				height:80px;
				width:80px;
			}
			&:after {
				display:inline-block;
				content:"";
				height:14px;
				width:8px;
				background: url($arrow-white-right) no-repeat 50% 50% / 8px 14px;
				margin-left:10px;
			}
		}
	}
	&.map .button-title:before {
		background: url($button_ico_search) no-repeat 50% 50% / 80px 80px;
		position:absolute;
		top:-85px;
		left:24px;
	}
	&.create {
		.button-title:before {
			background: url($button_ico_map) no-repeat 50% 50% / 80px 80px;
			position:absolute;
			top:-85px;
			left:24px;
		}
		.button-subtitle {
			max-width:240px;
			left: calc(50% - 120px);
			&:after {
				left:-90px;
			}
		}
	}
	&.jap {
		.button-title:before {
			background: url($button_ico_jap) no-repeat 50% 50% / 80px 80px;
			position:absolute;
			top:-85px;
			left:24px;
		}
		.button-subtitle {
			max-width:240px;
			left: calc(50% - 120px);
			&:after {
				left:-90px;
			}
		}
	}
	&.data .button-title:before {
		background:url($button_ico_data) no-repeat 50% 50% / 80px 80px;
		position:absolute;
		top:-85px;
		left:24px;
	}
	
	.button-subtitle {
	    text-align: center;
			color: $japanese_indigo;
			position: relative;
			left: calc(50% - 80px);
			max-width:160px;
			display:inline-block;
			bottom: 30px;
			z-index: 1;
			font-size:24px;
      min-height: 63px;
      vertical-align: bottom;
			&:after {
				display:inline-block;
				content:"";
				height:124px;
				width:420px;
				background: url($button_intersect) no-repeat 50% 50% / 420px 124px;
				position:absolute;
				bottom:-15px;
				left:-130px;
				z-index: -1;
			}
	}
}

a.search-button:hover {
	.title-wrapper {
		background:$mikado_yellow;
		.button-title {
			color:$japanese_indigo;
			border-bottom:$japanese_indigo solid 2px;
			&:after {
				background: url($arrow-indigo-right) no-repeat 50% 50% / 8px 14px;
			}
		}
	}
	&.map .button-title:before {
		background:url($button_ico_search_hover) no-repeat 50% 50% / 80px 80px;
	}
	&.create .button-title:before {
			background: url($button_ico_map_hover) no-repeat 50% 50% / 80px 80px;
	}
	&.data .button-title:before {
		background:url($button_ico_data_hover) no-repeat 50% 50% / 80px 80px;
	}
	&.jap .button-title:before {
		background: url($button_ico_jap_hover) no-repeat 50% 50% / 80px 80px;
	}
} 

// JUMP BUTTONS

a.jump-button {
  background: white;
  border: solid 1px #008DC9;
  display: inline-block;
  border-radius: 10px;
  padding: 30px 50px;
  color:$japanese_indigo;
  width:100%;
  .title-wrapper {
    .icon {
      display:inline-block;
      content:"";
      height:60px;
      width:60px;
      text-indent: -9999px;
      margin-right:50px;
      vertical-align:middle;
    }
    .button-title {
      border-bottom: solid 2px black;
      text-transform: uppercase;
      font-size:26px;
      font-family:$subtitle-font-family;
      display:inline;
      vertical-align: middle;
      &:after {
        display:inline-block;
        content:"";
        height:14px;
        width:8px;
        background: url($arrow-indigo-right) no-repeat 50% 50% / 8px 14px;
        margin-left:10px;
        margin-bottom:2px;
      }
    }
  }
  &.country .icon {
    background: url($jump-country) no-repeat 50% 50% / 60px 60px;
  }
  &.region .icon {
    background: url($jump-region) no-repeat 50% 50% / 60px 60px;
  }
  &.disease .icon {
    background: url($jump-disease) no-repeat 50% 50% / 60px 60px;
  }
  &.type .icon {
    background: url($jump-type) no-repeat 50% 50% / 60px 60px;
    margin-right:20px;
  }
  
  &:hover {
    color:$japanese_indigo;
    background:$mikado_yellow;
    border:$mikado_yellow solid 1px;
    &.country .icon {
      background: url($jump-country-hover) no-repeat 50% 50% / 60px 60px;
    }
    &.region .icon {
      background:url($jump-region-hover) no-repeat 50% 50% / 60px 60px;
    }
    &.disease .icon {
      background: url($jump-disease-hover) no-repeat 50% 50% / 60px 60px;
    }
    &.type .icon {
      background: url($jump-type-hover) no-repeat 50% 50% / 60px 60px;
    }
  }
}

#block-espen-jump-block-jump-block,
#block-espen-jump-block-home-jump-block {
  background-color: $azureish_white;
  padding:100px 20px;
  margin-bottom:30px;
}

#block-espen-jump-block-jump-block {
  .view {
    display:inline-block;
    width:100%;
    @media(min-width:960px) {
      width:calc(50% - 10px);
      margin-right:20px;
      &.view-jump-type,
      &.view-jump-country {
        margin-right:0;
      }
    }
    @media(min-width:1600px) {
      width:calc(25% - 15px);
      margin-right:20px;
      
      &.view-jump-country {
        margin-right:20px;
      }
    }
  }
}

#block-espen-jump-block-home-jump-block {
  margin: 0 -10px 30px;
  .view:not(.view-display-id-home):not(.view-id-maps_total) {
    display:inline-block;
    width:100%;
    @media(min-width:740px) {
      width:calc(50% - 10px);
      margin-right:20px;
      &.view-jump-country {
        margin-right:0;
      }
    }
    @media(min-width:1200px) {
      width:calc(33% - 12px);
      margin-right:20px;
      &.view-jump-country {
        margin-right:20px;
      }
      &.view-jump-type {
        margin-right:0;
      }
    }
    // @media(min-width:1600px) {
    //   width:calc(25% - 15px);
    //   margin-right:20px;
    // 
    //   &.view-jump-country {
    //     margin-right:20px;
    //   }
    // }
    a.jump-button {
      padding: 30px
    }
  }
}

#block-views-search-buttons-buttons,
.view-display-id-who_regions {
  h2 {
    display: flex;
    align-items: center;
    flex-flow: column wrap;
  }
}
// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary           : #2C3F4D; /* black */
$secondary         : #666666; /* grey */



// /* MAIN PALETTE */ //
$strong_blue        : #008DC9; /* strong_blue */
$bright_blue        : #42ACDC; /* bright_blue */
$faded_blue         : #1C5F89; /* faded_blue */
$middle_red_purple  : #0D1131; /* middle_red_purple */


// /* SEC PALETTE */ //
$pale_grey         : #F5F8FA; /* pale_grey */
$pale_grey_two     : #E4E8EB; /* pale_grey_two */
$battleship_grey   : #691E5B; /* battleship_grey */
$slate_grey        : #6c7983; /* slate_grey */
$japanese_indigo   : #2C3F4D; /* japanese_indigo */
$strong_orange     : #D86422; /* strong_orange */
$pearl_aqua        : #E59500; /* pearl_aqua */
$dark_lavender     : #E9D758; /* dark_lavender */
$mikado_yellow     : #FFC914; /* mikado_yellow */
$azureish_white    : #CCE8F4; /* azureish_white */
$antiflash_white   : #E9F1F7; /* antiflash_white */
$white_smoke       : #F7F7F8; /* white_smoke */
$stormcloud        : #4F5E69; /* stormcloud */
$black_coral       : #55626D; /* black_coral */
$picton_blue       : #3CABDD; /* picton_blue */
$ucla_blue         : #577D99; /* ucla_blue */
$kournikova        : #FFE07A; /* kournikova */

$black             : #000;
$grey              : #F2F2F2;
$white             : #FFF;

// PILLS
$pale_orange       : #F7E0D3;

// MENU
$middle_red					: #E28871;
$pastel_pink				: #E2B1A5;
$dust_storm					: #E2C7C0;
$timberwolf					: #E2D8D5;


// FILES
$dark_spring_green 		: #207347; /* xlsx */
$bdazzled_blue 				: #2D5994; /* docx */
$lemon_curry 					: #D19B15; /* jpg */
$internationl_orange 	: #C11E07; /* pdf */
$dark_pastel_red 			: #D04727; /* pptx */
$dark_imperial_blue 	: #023A6D; /* txt */
//$black 							: #000000; /* zip */

$xlsx_color	 					: $dark_spring_green; /* xlsx */
$docx_color	 					: $bdazzled_blue; /* docx */
$jpg_color	 					: $lemon_curry; /* jpg */
$pdf_color	 					: $internationl_orange; /* pdf */
$pptx_color	 					: $dark_pastel_red; /* pptx */
$txt_color	 					: $dark_imperial_blue; /* txt */
$zip_color	 					: black; /* zip */


// /* LINKS */ //
$action-default      : $strong_blue;
$action-hover        : $faded_blue;
$action-active       : $faded_blue;
$action-active-hover : $faded_blue;


// /* SHADOWS */ //
$box-shadow : none; //0 2px 4px 0 rgba(0,0,0,0.12);


// /* BORDERS */ //
$primary-border-color          : rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                : 1px solid $primary-border-color;

$secondary-border-color        : rgba(44, 63, 78,0.1);
$secondary-border-color-radius : 0;
$secondary-border              : 1px solid $secondary-border-color;

$tertiary-border-color         : #F3F3F3;
$tertiary-border-radius        : 0;
$tertiary-border               : 1px solid $tertiary-border-color;

$base-border                   : 1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;


// /* TABLES */ //
$table-bg-color           : $white_smoke;
$base-accent-color        : #477DCA;
$table-border-color       : rgba(44, 63, 77, 0.3);
$table-cell-border        : 1px solid $table-border-color;
$table-border             : 1px solid $table-border-color;
$table-header-bg-color    : $white;
$table-header-border      : none;
$table-hover-bg-color     : $grey;
$table-stripe-bg          : $antiflash_white;
$table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : 0.8rem 1rem;
$table-caption-bg-color   : $grey;
$table-caption-text-color : white;
$table-caption-border     : 1px solid white;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;

// /* FORMS  */ //
$fieldset-border-color : $secondary;
$form-box-shadow       : $box-shadow;
$form-box-shadow-focus : $box-shadow;
$form-border-color     : $strong_blue;
$form-border           : 1px solid $form-border-color;
$form-border-radius    : 40px;

$button-bg             : $strong_blue;
$button-hover-bg       : $action-hover;
$button-text           : white;
$button-border         : none;
$button-border-radius  : 0;
$button-hover-text     : white;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 13%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 13%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 18%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 15%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $pale_grey !default;
